.login_screen {
  width: 100dvw;
  height: 100dvh;
  background-color: #1a1b37;
  display: flex;
  overflow: hidden;
}

.lms_heading_container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.lms_title_container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: 6rem 0rem 0 6rem;
}

.lms_main_heading {
  font-size: 2rem;
  color: #fff;
  margin: 0;
  width: 40rem;
  font-family: "Lato", sans-serif;
}

.lms_sub_heading {
  font-size: 1.5rem;
  font-family: "Lato", sans-serif;
  color: #fff;
  margin: 0;
}

.lms_list_text {
  font-size: 1rem;
  font-family: "Lato", sans-serif;
  color: #fff;
}

.lms_unordered_group {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  margin-left: -2rem;
}

.lms_list_circle {
  width: 1rem;
  height: 1rem;
  background-color: transparent;
  border-radius: 100%;
  border: 3px solid #0b8314;
}

.list_group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.lms_image_container {
  padding: 2rem;
  background-color: #4d4f77;
  border: 1px solid #4d4f77;
  margin-left: -7rem;
  margin-bottom: -7rem;
  border-radius: 100%;
  width: 35rem;
  height: 35rem;
  position: relative;
}

.lms_image_container img {
  position: absolute;
  z-index: 10;
  top: 10%;
  left: 10%;
  width: 30rem;
  opacity: 1;
}

.input_half {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 1rem;
}

.lms_input_container {
  background-color: #fff;
  padding: 5rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: black;
  border: 0.0625rem solid #707070;
  border-radius: 2.0625rem;
  margin-top: 5rem;
  margin-right: 10rem;
}

.lms_input_heading {
  font-size: 1.5rem;
  font-family: "Lato", sans-serif;
}

form {
  padding: 1rem 0;
  width: 100%;
}

.lms_input_button_group {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
}

.lms_input_submit {
  padding: 1rem 5rem;
  border-radius: 0.7rem;
  border: none;
  background-color: #1a1b37;
  color: #fff;
  cursor: pointer;
  font-family: "Lato", sans-serif;
}

.lms_input_submit:focus {
  outline: none;
}

.lms_admin_heading {
  color: rgb(1, 112, 185);
  font-family: "Lato", sans-serif;
  font-size: 1.2rem;
  cursor: pointer;
}

.lms_admin_heading:hover {
  text-decoration: underline;
}