.loader-container {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-container img {
  display: block;
  max-width: 30rem;
  max-height: 30rem;
  width: auto;
  height: auto;
}
