.armiore_video_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -10;
}
.armiore_video_container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.armoire_text_container {
  height: 80dvh;
  color: aliceblue;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}
.styleUs_heading {
  font-size: 5rem;
}
.stylus_button_group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
