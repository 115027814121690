.evaluation_screen {
  width: 100dvw;
  height: 100dvh;
  background-color: #f7f7f7;
  overflow-y: auto;
  padding: 3rem 6.5rem 3rem 6.5rem;
}
.evaluation_sub_heading {
  font-size: 1.6rem;
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
}
.evaluation_item_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}
.evaluation_item {
  width: 100%;
  padding: 2rem;
  border: 1px solid #00ce10;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.evaluation_question_group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  font-size: 1.5rem;

  margin-bottom: 1.5rem;
}
.evaluation_answer {
  padding: 0rem;
  text-align: justify;
  font-size: 1.2rem;
  color: #707070;
  margin-bottom: 1.5rem;
}
.feedback_section {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  flex-direction: column;
}
.feedback_main_heading {
  font-size: 1.2rem;
}
.feedback_heading {
  font-size: 1.3rem;

  width: 9rem;
}
.score_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.evaluation_input {
  height: 3rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 8rem;
  border: 0.0625rem solid #707070;
  border-radius: 0.8rem;
  opacity: 1;
  font-size: 1.3rem;
  font-family: "Lato", sans-serif;
  padding-left: 1rem;
}

.feedback_block {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.evaluation_textarea {
  resize: none;
  height: 10rem;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 0.8rem;
  padding: 1rem;
  font-size: 1rem;
}
.feedback_block_group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  flex: 1;
}
.manual_question_group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  flex-direction: column;
}
