.pdp_screen_container {
  height: auto;
  width: 100%;
  background-image: url("../../assets/Subtaction\ 1.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;
}
.pdp_screen_container .pdp_zdlogo {
  position: absolute;
  top: 2rem;
  left: 2rem;
  cursor: pointer;
}
.pdp_zdlogo img {
  height: 4rem;
  width: auto;
}

.pdp_screen_container .pdp_screen_header {
  font-size: 3rem;
  color: white;
  font-family: "Lato", sans-serif;
}
.pdp_gif img {
  width: 50rem;
  height: auto;
}

/* ////////////////////////////////////////Dicover Screen /////////////////////////// */

.discover_screen {
  height: auto;
  width: 100%;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8rem;
}
.threed_card_container .discover_card {
  width: 22rem;
  height: 22rem;
  background-color: #e8e9eb;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.discover_card .discover_img_container {
  width: 18rem;
  height: 18rem;
  background-color: #fff;
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
  margin-top: -5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.discover_card:nth-child(1) .discover_img_container {
  border-radius: 1.5rem 0 0 1.5rem;
}
.discover_card:nth-last-child(1) .discover_img_container {
  border-radius: 0rem 1.5rem 1.5rem 0rem;
}

.discover_card .discover_img_container img {
  width: auto;
  height: auto;
  max-width: 18rem;
}
.discover_card .discover_description {
  width: 15rem;
  font-family: "Lato", sans-serif;
  margin-top: 1.5rem;
  font-size: 1.1rem;
}
/* ////////////////////////////////////OUR SAMPLE/////////////////////////////////// */

.our_sample_screen {
  height: auto;
  width: 100%;
  padding: 5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15rem;
}
.our_sample_screen .our_sample_header_container {
  color: #717171;
  font-family: "Lato", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.our_sample_header_container .our_sample_header {
  font-size: 3rem;
}
.our_sample_header_container .our_sample_description {
  width: 30rem;
  text-align: center;
  font-size: 1.2rem;
  margin-top: 1rem;
}
.our_sample_screen .iframe_container {
  width: 65rem;
  height: 50rem;
  background-color: #eeeeee;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.our_sample_screen .iframe_container iframe {
  width: 90%;
  height: 90%;
}

/* //////////////////////////////////////FOOTER//////////////////////////////// */
.footer_screen {
  background-color: #021a3b;
  width: 100%;
}

/* /////////////////////////////////////Our 3d Models////////////////////////////// */
.ourthreed_screen {
  height: auto;
  width: 100%;
  padding: 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8rem;
}
.ourthreed_header {
  color: #707070;
  font-size: 3rem;
  font-family: "Lato", sans-serif;
}
.threed_card_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 2rem;
}
.threed_card {
  width: 20rem;
  height: 27rem;
  background-color: #e8e9eb;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.2s ease;
}
.threed_card_hover {
  width: 20rem;
  height: 27rem;
  background-color: #093067;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.2s ease;
}
.threed_card .threed_image {
  margin-top: -5rem;
  transition: all 0.2s ease;
}
.threed_card .threed_image img {
  width: auto;
  height: auto;
  max-width: 20rem;
  max-height: 20rem;
}
.threed_card_hover .threed_image {
  margin-top: -5rem;
  transition: all 0.2s ease;
  scale: 1.2;
}
.threed_card_hover .threed_image img {
  width: auto;
  height: auto;
  max-width: 20rem;
  max-height: 20rem;
}

.threed_card .card_body {
  width: 100%;
  padding: 0rem 1.5rem;
}

.threed_card .card_body .card_title {
  font-size: 1.5rem;
  font-family: "Lato", sans-serif;
  color: black;
  font-weight: bold;
  transition: all 0.2s ease;
}
.threed_card .card_body .card_dscription {
  margin-top: 1rem;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  color: black;
  transition: all 0.2s ease;
}
.threed_card .card_body .card_view_demo {
  margin-top: 2rem;
  font-family: "Lato", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  color: #093067;
  transition: all 0.2s ease;
}

.threed_card_hover .card_body {
  width: 100%;
  padding: 0rem 1.5rem;
}

.threed_card_hover .card_body .card_title {
  font-size: 1.5rem;
  font-family: "Lato", sans-serif;
  color: white;
  font-weight: bold;
  transition: all 0.2s ease;
}
.threed_card_hover .card_body .card_dscription {
  margin-top: 1rem;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  color: white;
  transition: all 0.2s ease;
}
.threed_card_hover .card_body .card_view_demo {
  margin-top: 2rem;
  font-family: "Lato", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
  text-decoration: underline;
  transition: all 0.2s ease;
}

/* ////////////////////////////////////////Media Queries////////////////////////////////////// */

@media (min-width: 300px) and (max-width: 424px) {
  html,
  body {
    font-size: 6px;
  }
  .pdp_screen_container {
    height: auto;
    width: 100%;
    background-image: url("");
    background-color: #021a3b;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10rem 0;
  }
  .pdp_screen_container .pdp_screen_header {
    font-size: 2.5rem;
    color: white;
    font-family: "Lato", sans-serif;
    text-align: center;
  }
  .pdp_gif img {
    width: 40rem;
    height: auto;
  }
  .threed_card_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 10rem;
  }
  .our_sample_screen {
    height: auto;
    width: 100%;
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
  }
  .our_sample_screen .iframe_container {
    width: 50rem;
    height: 50rem;
    background-color: #eeeeee;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .threed_card_container .discover_card {
    width: 30rem;
    height: 30rem;
    background-color: #e8e9eb;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  }
  .discover_card .discover_img_container {
    width: 22rem;
    height: 22rem;
    background-color: #fff;
    filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
    margin-top: -5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .discover_card:nth-child(1) .discover_img_container {
    border-radius: 1.5rem 0 0 1.5rem;
  }
  .discover_card:nth-last-child(1) .discover_img_container {
    border-radius: 0rem 1.5rem 1.5rem 0rem;
  }

  .discover_card .discover_img_container img {
    width: auto;
    height: auto;
    max-width: 20rem;
  }
  .discover_card .discover_description {
    width: 20rem;
    font-family: "Lato", sans-serif;
    margin-top: 1.5rem;
    font-size: 1.5rem;
  }

  .threed_card {
    width: 25rem;
    height: 32rem;
    background-color: #e8e9eb;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  .threed_card_hover {
    width: 25rem;
    height: 32rem;
    background-color: #093067;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  .threed_card .threed_image {
    margin-top: -5rem;
    transition: all 0.2s ease;
  }
  .threed_card .threed_image img {
    width: auto;
    height: auto;
    max-width: 20rem;
    max-height: 20rem;
  }
  .threed_card_hover .threed_image {
    margin-top: -5rem;
    transition: all 0.2s ease;
    scale: 1.2;
  }
  .threed_card_hover .threed_image img {
    width: auto;
    height: auto;
    max-width: 20rem;
    max-height: 20rem;
  }

  .threed_card .card_body {
    width: 100%;
    padding: 0rem 1.5rem;
  }

  .threed_card .card_body .card_title {
    font-size: 2rem;
    font-family: "Lato", sans-serif;
    color: black;
    font-weight: bold;
    transition: all 0.2s ease;
  }
  .threed_card .card_body .card_dscription {
    margin-top: 1rem;
    font-family: "Lato", sans-serif;
    font-size: 1.4rem;
    color: black;
    transition: all 0.2s ease;
  }
  .threed_card .card_body .card_view_demo {
    margin-top: 2rem;
    font-family: "Lato", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    color: #093067;
    transition: all 0.2s ease;
  }

  .threed_card_hover .card_body {
    width: 100%;
    padding: 0rem 1.5rem;
  }

  .threed_card_hover .card_body .card_title {
    font-size: 2rem;
    font-family: "Lato", sans-serif;
    color: white;
    font-weight: bold;
    transition: all 0.2s ease;
  }
  .threed_card_hover .card_body .card_dscription {
    margin-top: 1rem;
    font-family: "Lato", sans-serif;
    font-size: 1.4rem;
    color: white;
    transition: all 0.2s ease;
  }
  .threed_card_hover .card_body .card_view_demo {
    margin-top: 2rem;
    font-family: "Lato", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    text-decoration: underline;
    transition: all 0.2s ease;
  }
}
@media (min-width: 425px) and (max-width: 550px) {
  html,
  body {
    font-size: 7px;
  }
  .pdp_screen_container {
    height: auto;
    width: 100%;
    background-image: url("");
    background-color: #021a3b;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10rem 0;
  }
  .pdp_screen_container .pdp_screen_header {
    font-size: 2.5rem;
    color: white;
    font-family: "Lato", sans-serif;
    text-align: center;
  }
  .pdp_gif img {
    width: 40rem;
    height: auto;
  }
  .threed_card_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 10rem;
  }
  .our_sample_screen {
    height: auto;
    width: 100%;
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
  }
  .our_sample_screen .iframe_container {
    width: 50rem;
    height: 50rem;
    background-color: #eeeeee;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .threed_card_container .discover_card {
    width: 30rem;
    height: 30rem;
    background-color: #e8e9eb;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  }
  .discover_card .discover_img_container {
    width: 22rem;
    height: 22rem;
    background-color: #fff;
    filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
    margin-top: -5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .discover_card:nth-child(1) .discover_img_container {
    border-radius: 1.5rem 0 0 1.5rem;
  }
  .discover_card:nth-last-child(1) .discover_img_container {
    border-radius: 0rem 1.5rem 1.5rem 0rem;
  }

  .discover_card .discover_img_container img {
    width: auto;
    height: auto;
    max-width: 20rem;
  }
  .discover_card .discover_description {
    width: 20rem;
    font-family: "Lato", sans-serif;
    margin-top: 1.5rem;
    font-size: 1.5rem;
  }

  .threed_card {
    width: 25rem;
    height: 32rem;
    background-color: #e8e9eb;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  .threed_card_hover {
    width: 25rem;
    height: 32rem;
    background-color: #093067;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  .threed_card .threed_image {
    margin-top: -5rem;
    transition: all 0.2s ease;
  }
  .threed_card .threed_image img {
    width: auto;
    height: auto;
    max-width: 20rem;
    max-height: 20rem;
  }
  .threed_card_hover .threed_image {
    margin-top: -5rem;
    transition: all 0.2s ease;
    scale: 1.2;
  }
  .threed_card_hover .threed_image img {
    width: auto;
    height: auto;
    max-width: 20rem;
    max-height: 20rem;
  }

  .threed_card .card_body {
    width: 100%;
    padding: 0rem 1.5rem;
  }

  .threed_card .card_body .card_title {
    font-size: 2rem;
    font-family: "Lato", sans-serif;
    color: black;
    font-weight: bold;
    transition: all 0.2s ease;
  }
  .threed_card .card_body .card_dscription {
    margin-top: 1rem;
    font-family: "Lato", sans-serif;
    font-size: 1.4rem;
    color: black;
    transition: all 0.2s ease;
  }
  .threed_card .card_body .card_view_demo {
    margin-top: 2rem;
    font-family: "Lato", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    color: #093067;
    transition: all 0.2s ease;
  }

  .threed_card_hover .card_body {
    width: 100%;
    padding: 0rem 1.5rem;
  }

  .threed_card_hover .card_body .card_title {
    font-size: 2rem;
    font-family: "Lato", sans-serif;
    color: white;
    font-weight: bold;
    transition: all 0.2s ease;
  }
  .threed_card_hover .card_body .card_dscription {
    margin-top: 1rem;
    font-family: "Lato", sans-serif;
    font-size: 1.4rem;
    color: white;
    transition: all 0.2s ease;
  }
  .threed_card_hover .card_body .card_view_demo {
    margin-top: 2rem;
    font-family: "Lato", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    text-decoration: underline;
    transition: all 0.2s ease;
  }
}

@media (min-width: 551px) and (max-width: 675px) {
  html,
  body {
    font-size: 8px;
  }
  .pdp_screen_container {
    height: auto;
    width: 100%;
    background-image: url("");
    background-color: #021a3b;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10rem 0;
  }
  .pdp_screen_container .pdp_screen_header {
    font-size: 2.5rem;
    color: white;
    font-family: "Lato", sans-serif;
    text-align: center;
  }
  .pdp_gif img {
    width: 40rem;
    height: auto;
  }
  .threed_card_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 10rem;
  }
  .our_sample_screen {
    height: auto;
    width: 100%;
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
  }
  .our_sample_screen .iframe_container {
    width: 50rem;
    height: 50rem;
    background-color: #eeeeee;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .threed_card_container .discover_card {
    width: 30rem;
    height: 30rem;
    background-color: #e8e9eb;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  }
  .discover_card .discover_img_container {
    width: 22rem;
    height: 22rem;
    background-color: #fff;
    filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
    margin-top: -5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .discover_card:nth-child(1) .discover_img_container {
    border-radius: 1.5rem 0 0 1.5rem;
  }
  .discover_card:nth-last-child(1) .discover_img_container {
    border-radius: 0rem 1.5rem 1.5rem 0rem;
  }

  .discover_card .discover_img_container img {
    width: auto;
    height: auto;
    max-width: 20rem;
  }
  .discover_card .discover_description {
    width: 20rem;
    font-family: "Lato", sans-serif;
    margin-top: 1.5rem;
    font-size: 1.5rem;
  }

  .threed_card {
    width: 25rem;
    height: 32rem;
    background-color: #e8e9eb;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  .threed_card_hover {
    width: 25rem;
    height: 32rem;
    background-color: #093067;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  .threed_card .threed_image {
    margin-top: -5rem;
    transition: all 0.2s ease;
  }
  .threed_card .threed_image img {
    width: auto;
    height: auto;
    max-width: 20rem;
    max-height: 20rem;
  }
  .threed_card_hover .threed_image {
    margin-top: -5rem;
    transition: all 0.2s ease;
    scale: 1.2;
  }
  .threed_card_hover .threed_image img {
    width: auto;
    height: auto;
    max-width: 20rem;
    max-height: 20rem;
  }

  .threed_card .card_body {
    width: 100%;
    padding: 0rem 1.5rem;
  }

  .threed_card .card_body .card_title {
    font-size: 2rem;
    font-family: "Lato", sans-serif;
    color: black;
    font-weight: bold;
    transition: all 0.2s ease;
  }
  .threed_card .card_body .card_dscription {
    margin-top: 1rem;
    font-family: "Lato", sans-serif;
    font-size: 1.4rem;
    color: black;
    transition: all 0.2s ease;
  }
  .threed_card .card_body .card_view_demo {
    margin-top: 2rem;
    font-family: "Lato", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    color: #093067;
    transition: all 0.2s ease;
  }

  .threed_card_hover .card_body {
    width: 100%;
    padding: 0rem 1.5rem;
  }

  .threed_card_hover .card_body .card_title {
    font-size: 2rem;
    font-family: "Lato", sans-serif;
    color: white;
    font-weight: bold;
    transition: all 0.2s ease;
  }
  .threed_card_hover .card_body .card_dscription {
    margin-top: 1rem;
    font-family: "Lato", sans-serif;
    font-size: 1.4rem;
    color: white;
    transition: all 0.2s ease;
  }
  .threed_card_hover .card_body .card_view_demo {
    margin-top: 2rem;
    font-family: "Lato", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    text-decoration: underline;
    transition: all 0.2s ease;
  }
}

@media (min-width: 676px) and (max-width: 799px) {
  html,
  body {
    font-size: 9px;
  }
  .pdp_screen_container {
    height: auto;
    width: 100%;
    background-image: url("");
    background-color: #021a3b;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10rem 0;
  }
  .pdp_screen_container .pdp_screen_header {
    font-size: 2.5rem;
    color: white;
    font-family: "Lato", sans-serif;
    text-align: center;
  }
  .pdp_gif img {
    width: 40rem;
    height: auto;
  }
  .threed_card_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 10rem;
  }
  .our_sample_screen {
    height: auto;
    width: 100%;
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
  }
  .our_sample_screen .iframe_container {
    width: 50rem;
    height: 50rem;
    background-color: #eeeeee;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .threed_card_container .discover_card {
    width: 30rem;
    height: 30rem;
    background-color: #e8e9eb;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  }
  .discover_card .discover_img_container {
    width: 22rem;
    height: 22rem;
    background-color: #fff;
    filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
    margin-top: -5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .discover_card:nth-child(1) .discover_img_container {
    border-radius: 1.5rem 0 0 1.5rem;
  }
  .discover_card:nth-last-child(1) .discover_img_container {
    border-radius: 0rem 1.5rem 1.5rem 0rem;
  }

  .discover_card .discover_img_container img {
    width: auto;
    height: auto;
    max-width: 20rem;
  }
  .discover_card .discover_description {
    width: 20rem;
    font-family: "Lato", sans-serif;
    margin-top: 1.5rem;
    font-size: 1.5rem;
  }

  .threed_card {
    width: 25rem;
    height: 32rem;
    background-color: #e8e9eb;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  .threed_card_hover {
    width: 25rem;
    height: 32rem;
    background-color: #093067;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  .threed_card .threed_image {
    margin-top: -5rem;
    transition: all 0.2s ease;
  }
  .threed_card .threed_image img {
    width: auto;
    height: auto;
    max-width: 20rem;
    max-height: 20rem;
  }
  .threed_card_hover .threed_image {
    margin-top: -5rem;
    transition: all 0.2s ease;
    scale: 1.2;
  }
  .threed_card_hover .threed_image img {
    width: auto;
    height: auto;
    max-width: 20rem;
    max-height: 20rem;
  }

  .threed_card .card_body {
    width: 100%;
    padding: 0rem 1.5rem;
  }

  .threed_card .card_body .card_title {
    font-size: 2rem;
    font-family: "Lato", sans-serif;
    color: black;
    font-weight: bold;
    transition: all 0.2s ease;
  }
  .threed_card .card_body .card_dscription {
    margin-top: 1rem;
    font-family: "Lato", sans-serif;
    font-size: 1.4rem;
    color: black;
    transition: all 0.2s ease;
  }
  .threed_card .card_body .card_view_demo {
    margin-top: 2rem;
    font-family: "Lato", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    color: #093067;
    transition: all 0.2s ease;
  }

  .threed_card_hover .card_body {
    width: 100%;
    padding: 0rem 1.5rem;
  }

  .threed_card_hover .card_body .card_title {
    font-size: 2rem;
    font-family: "Lato", sans-serif;
    color: white;
    font-weight: bold;
    transition: all 0.2s ease;
  }
  .threed_card_hover .card_body .card_dscription {
    margin-top: 1rem;
    font-family: "Lato", sans-serif;
    font-size: 1.4rem;
    color: white;
    transition: all 0.2s ease;
  }
  .threed_card_hover .card_body .card_view_demo {
    margin-top: 2rem;
    font-family: "Lato", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    text-decoration: underline;
    transition: all 0.2s ease;
  }
}

@media (min-width: 800px) and (max-width: 1024px) {
  html,
  body {
    font-size: 10px;
  }
  .pdp_screen_container {
    height: auto;
    width: 100%;
    background-image: url("");
    background-color: #021a3b;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10rem 0;
  }
  .pdp_screen_container .pdp_screen_header {
    font-size: 2.5rem;
    color: white;
    font-family: "Lato", sans-serif;
    text-align: center;
  }
  .pdp_gif img {
    width: 40rem;
    height: auto;
  }
  .threed_card_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 10rem;
  }
  .our_sample_screen {
    height: auto;
    width: 100%;
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
  }
  .our_sample_screen .iframe_container {
    width: 50rem;
    height: 50rem;
    background-color: #eeeeee;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .threed_card_container .discover_card {
    width: 30rem;
    height: 30rem;
    background-color: #e8e9eb;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  }
  .discover_card .discover_img_container {
    width: 22rem;
    height: 22rem;
    background-color: #fff;
    filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
    margin-top: -5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .discover_card:nth-child(1) .discover_img_container {
    border-radius: 1.5rem 0 0 1.5rem;
  }
  .discover_card:nth-last-child(1) .discover_img_container {
    border-radius: 0rem 1.5rem 1.5rem 0rem;
  }

  .discover_card .discover_img_container img {
    width: auto;
    height: auto;
    max-width: 20rem;
  }
  .discover_card .discover_description {
    width: 20rem;
    font-family: "Lato", sans-serif;
    margin-top: 1.5rem;
    font-size: 1.5rem;
  }

  .threed_card {
    width: 25rem;
    height: 32rem;
    background-color: #e8e9eb;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  .threed_card_hover {
    width: 25rem;
    height: 32rem;
    background-color: #093067;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  .threed_card .threed_image {
    margin-top: -5rem;
    transition: all 0.2s ease;
  }
  .threed_card .threed_image img {
    width: auto;
    height: auto;
    max-width: 20rem;
    max-height: 20rem;
  }
  .threed_card_hover .threed_image {
    margin-top: -5rem;
    transition: all 0.2s ease;
    scale: 1.2;
  }
  .threed_card_hover .threed_image img {
    width: auto;
    height: auto;
    max-width: 20rem;
    max-height: 20rem;
  }

  .threed_card .card_body {
    width: 100%;
    padding: 0rem 1.5rem;
  }

  .threed_card .card_body .card_title {
    font-size: 2rem;
    font-family: "Lato", sans-serif;
    color: black;
    font-weight: bold;
    transition: all 0.2s ease;
  }
  .threed_card .card_body .card_dscription {
    margin-top: 1rem;
    font-family: "Lato", sans-serif;
    font-size: 1.4rem;
    color: black;
    transition: all 0.2s ease;
  }
  .threed_card .card_body .card_view_demo {
    margin-top: 2rem;
    font-family: "Lato", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    color: #093067;
    transition: all 0.2s ease;
  }

  .threed_card_hover .card_body {
    width: 100%;
    padding: 0rem 1.5rem;
  }

  .threed_card_hover .card_body .card_title {
    font-size: 2rem;
    font-family: "Lato", sans-serif;
    color: white;
    font-weight: bold;
    transition: all 0.2s ease;
  }
  .threed_card_hover .card_body .card_dscription {
    margin-top: 1rem;
    font-family: "Lato", sans-serif;
    font-size: 1.4rem;
    color: white;
    transition: all 0.2s ease;
  }
  .threed_card_hover .card_body .card_view_demo {
    margin-top: 2rem;
    font-family: "Lato", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    text-decoration: underline;
    transition: all 0.2s ease;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  html,
  body {
    font-size: 10px;
  }
}

@media (min-width: 1281px) and (max-width: 1536px) {
  html,
  body {
    font-size: 12px;
  }
}

@media (min-width: 1537px) and (max-width: 1792px) {
  html,
  body {
    font-size: 14px;
  }
}

@media (min-width: 1793px) and (max-width: 2048px) {
  html,
  body {
    font-size: 16px;
  }
}

@media (min-width: 2049px) and (max-width: 2560px) {
  html,
  body {
    font-size: 18px;
  }
}

@media (min-width: 2561px) and (max-width: 3840px) {
  html,
  body {
    font-size: 20px;
  }
}
