.lms_popup_box {
  padding: 1.5rem 3.5rem;
  background-color: white;
  border-radius: 1.5rem;
  position: relative;
}
.lms_close_icon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.lms_close_icon img {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}
.lms_popup_contents {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.lms_popup_desc {
  font-size: 1.2rem;
}
.lms_modal_button {
  font-size: 1.2rem;
  color: white;
  background-color: #1a1b37;
  padding: 0.5rem 1.7rem;
  border-radius: 1.5rem;
  cursor: pointer;
  margin-top: 1rem;
}
.lms_modal_cancel {
  font-size: 1.2rem;
  color: black;
  background-color: lightgray;
  padding: 0.5rem 1.7rem;
  border-radius: 1.5rem;
  cursor: pointer;
  margin-top: 1rem;
}
