.armoire_navbar_container {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: transparent;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  color: white;
  padding: 1rem 2rem;
  z-index: 10;
}
.armoire_navbar_container nav img {
  width: auto;
  height: 4rem;
}
