.input_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.input_label_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  width: 40rem;
}

.login_input {
  width: 29.6875rem;
  height: 4.375rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #d5d5d5;
  border-radius: 1.25rem;
  opacity: 1;
  padding-left: 1rem;
  font-size: 1.3rem;
  font-family: "Lato", sans-serif;
}

input:focus {
  outline: none;
}

.lms_input_error {
  color: red;
  font-size: 1rem;
  font-family: "Lato", sans-serif;
  margin-left: -11rem;
}

.lms_label {
  font-size: 1.5rem;
  color: #000000;
  font-family: "Lato", sans-serif;
}
