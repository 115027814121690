.testscreen_container {
  width: 100dvw;
  height: 100dvh;
  overflow-y: auto;
}

.testscreen_user {
  font-size: 2rem;
  font-weight: 600;
  color: #1a1b37;
  padding: 3rem 3rem;
}

.test_heading {
  font-size: 1.5rem;
  color: #000000;
  font-weight: 600;
  padding: 0rem 5rem;
}

.test_questions_container {
  padding: 3rem 5rem;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.test_card {
  width: 100%;
  padding: 1.8rem;
  border: 1px solid green;
  border-radius: 2.125rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.test_question_group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  font-size: 1.8rem;
  color: #000000;
}
.test_question_group_image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.test_question_group_image img {
  width: auto;
  height: auto;
  max-width: 10rem;
  max-height: 10rem;
}

.test_question {
  font-size: 1.8rem;
  color: #000000;
}

.options_container {
  display: flex;
  margin: 1.5rem 0 1rem 1rem;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}

.optiongroup {
  display: flex;
  align-items: center;
  width: auto;

  justify-content: flex-start;
  gap: 1rem;
  margin-right: 10rem;
}
.options_container textarea {
  width: 98%;
  height: 10rem;
  resize: none;
  font-family: "Lato", sans-serif;
  padding: 1rem;
  border: 1px solid #707070;
  border-radius: 0.3rem;
}
textarea:focus {
  outline: none;
}

.test_radio {
  width: 1.5rem;
  height: 1.5rem;
}
.test_checkbox {
  width: 1.8rem;
  height: 1.8rem;
  accent-color: white;
  margin-left: -0.05rem;
}

label {
  font-size: 1.2rem;
  cursor: pointer;
}

.test_submit {
  font-size: 1.2rem;
  color: white;
  background-color: #1a1b37;
  padding: 0.5rem 1.7rem;
  border-radius: 1.5rem;

  top: 3rem;
  right: 2rem;
  cursor: pointer;
}

.test_radio:checked {
  accent-color: black;
}

.loading_screen {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.test_loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
.skip_question {
  font-size: 1rem;
  color: #3498db;
  cursor: pointer;
}
.skip_question:hover {
  text-decoration: underline;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.correctIcon {
  display: block;
  max-width: 1.7rem;
  max-height: 1.7rem;
  width: auto;
  height: auto;
}

.date_time_group {
  padding: 0 5rem;
  font-size: 1.5rem;
  font-weight: 600;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal_screen {
  position: fixed;
  width: 100%;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal_container {
  padding: 3rem;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  position: relative;
}
.modal_close_container {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
.modal_close_container img {
  width: 2rem;
  height: 2rem;
}
.modal_desc {
  width: auto;
  max-width: 45rem;
  padding: 0rem 1rem;
  text-align: justify;
  font-size: 1.2rem;
  color: #707070;
}

.modal_heading {
  font-size: 1.8rem;
}
.modal_textarea_group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-direction: column;
}
.modal_textarea_group textarea {
  width: 45rem;
  height: 10rem;
  resize: none;
  font-family: "Lato", sans-serif;
  padding: 1rem;
  border: 1px solid #707070;
}
.modal_textarea_group textarea:focus {
  outline: none;
}

label img {
  width: auto;
  max-width: 10rem;
  max-height: 10rem;
}
.modal_date {
  font-size: 1.2rem;

  color: #000000;
  padding: 0rem 1rem;
}
