.about_container {
  width: 100dvw;
  height: 100dvh;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.about_title_bar {
  width: 100%;
  padding: 1rem;
  border: 1px solid #707070;
}
.about_title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-left: 2rem;
}
.about_finish_bar {
  width: 100%;
  padding: 1rem 5rem;
  border: 1px solid #707070;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.about_finish_bar .about_finish_button {
  padding: 0.2rem 2.5rem;
  border: 1px solid #707070;
  border-radius: 1rem;
  cursor: pointer;
}
.about_finish_button:hover {
  background-color: #707070;
  color: white;
}
.about_content_container {
  flex: 1;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.about_content {
  width: 85%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding: 1rem 0;
}
.about_content .about_content_title {
  font-size: 1.5rem;
  font-weight: 600;
}

.about_content .about_content_desc {
  font-size: 1.3rem;
}
.about_content_iframe {
  width: 100%;
  height: 30rem;
  flex: 1;
}
.about_content_iframe iframe {
  width: 100%;
  height: 100%;
}
