.lesson_screen {
  width: 100dvw;
  height: 100dvh;
  background-color: #f7f7f7;
  overflow-y: auto;
  padding: 0 6rem;
}

.dropdown_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 2rem;
}

.dropdown_title {
  font-size: 1.8rem;
  font-weight: 600;
}

.lessons_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 0rem;
  flex-direction: column;
  gap: 1rem;
  margin: 3rem 0;
}

.lessons_card {
  padding: 2rem;
  border: 3px solid #1a1b37;
  background-color: white;
  border-radius: 2.125rem;
  width: 80%;
}

.card_divider {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.text_title {
  font-size: 1.8rem;
}

.option_subGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
  margin-top: 1.5rem;
}

.dropdown_box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.view_results_button {
  padding: 1rem 1.7rem;
  background-color: #1a1b37;
  color: #f7f7f7;
  border-radius: 2rem;
  font-size: 1rem;
  cursor: pointer;
}

.dropdown_box_container {
  padding: 1rem 1.7rem;
  color: #1a1b37;
  border-radius: 2rem;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  border: 1px solid #1a1b37;
  position: relative;
  cursor: pointer;
}

.dropdown_box_container img {
  width: 1rem;
  height: 1rem;
}

.dropdown_box_title {
  font-size: 1rem;
  color: #1a1b37;
}

.dropdown_menu_container {
  position: absolute;
  top: 3.8rem;
  right: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  flex-direction: column;
  justify-content: center;
  border: 0.5px solid gainsboro;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.dropdown_option_text {
  width: 9rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  font-size: 1rem;
}

.dropdown_option_text:hover {
  color: black;
  background-color: rgb(199, 200, 201);
}
.lms_loader_screen {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkbox_img img {
  width: 2.5rem;
  height: 2.5rem;
}

.cohort_box {
  padding: 0.3rem 0.5rem;
  background-color: #1a1b37;
  color: white;
  border-radius: 1rem;
}
