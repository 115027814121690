.styleus_iframe {
  position: fixed;
  display: flex;
  flex-direction: row-reverse;
  z-index: 10;
  height: 100dvh;
  width: 35rem;
  right: 0;
  bottom: 0;
}
.styleus_iframe iframe {
  width: 100%;
  height: 100%;
}
.close_icon_iframe img {
  width: 1.5rem;
  height: 1.5rem;
}
.close_icon_iframe {
  margin: 1rem -3rem 0 0;
  cursor: pointer;
  z-index: 100;
  background-color: #b4b4b4;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.style_us_colorName {
  font-size: 1.2rem;
  width: 30rem;
  font-weight: 600;
}
.style_us_colorName span {
  font-weight: 400;
}

.armoire_product_page_container {
  display: flex;
  gap: 2rem;
  margin: 3rem 0;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 2rem;
}
.armoire_product_page_content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  gap: 5rem;
}
.armoire_image_container {
  width: 25rem;
  height: 40rem;
  position: relative;
  background-color: aqua;
}
.armoire_image_container .trythreed_button {
  position: absolute;

  bottom: 0.5rem;
  right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.1rem;
  padding: 0.5rem;
  border-radius: 100%;
  width: 3.5rem;
  height: 3.5rem;
  cursor: pointer;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}
.armoire_image_container .trythreed_button img {
  width: 1.5rem;
  height: 1.5em;
}
.armoire_threed_text {
  font-size: 0.7rem;
}
.armoire_image_container img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.armoire_description_group {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.armoire_main_heading {
  font-size: 3rem;
  font-weight: 600;
}
