.viewDemo_container {
  background-color: #024040;
}
.bike_group_90a {
  background-color: #024040;
  background-image: url(../../assets/desktopbg.png);
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  overflow: hidden;
  -ms-overflow-style: none;
  background-position: center;
  scrollbar-width: none;
}

.viewDemo_container .bike_screen {
  position: relative;
  height: 54rem;
  width: 100%;
  display: flex;
  align-items: center;
}

.viewDemo_container .bike_screen .viewDemo_zdlogo {
  position: absolute;
  top: 2rem;
  left: 2rem;
}

.viewDemo_container .bike_screen .viewDemo_zdlogo img {
  height: auto;
  width: auto;
  max-height: 10rem;
  max-width: 10rem;
  cursor: pointer;
}

.viewDemo_content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  gap: 3rem;
}

.ViewDemo_heading {
  font-size: 3.875rem;
  width: 28rem;
  text-align: center;
  color: white;
  font-family: "Lato", sans-serif;
  font-weight: 600;
}

.viewDemogif_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.viewDemo_gif img {
  display: block;
  max-width: 60rem;
  max-height: 60rem;
  width: auto;
  height: auto;
}

.viewDemo_button {
  width: 14rem;
  height: 3rem;
  display: flex;
  border-radius: 0.4375rem;
  align-items: center;
  justify-content: center;
  background-color: #0693ff;
  font-family: "Segoi UI";
  cursor: pointer;
  color: white;
  font-size: 1rem;
}

/* ///////////////////////////////////////////card screen////////////////////////////////////// */

.card_screen {
  height: auto;
  width: 100%;
  padding: 2rem 0rem;
}

.card_screen .cardScreen_heading {
  padding-bottom: 13%;
  /* margin: -0rem 0 2rem 0; */

  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  font-size: 2.5rem;
  color: #ccff00;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.card_screen .card_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10rem;
}

.card_container .card_box_hover {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 30rem;
  width: 22rem;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0rem 0.1875rem 0.375rem #00000029;
  border-radius: 3.5rem;
  cursor: pointer;
  color: white;
  transition: all 0.2s ease;
  outline: 1px solid #b4b4b4;
  transition: all 0.5s ease;
}

.card_container .card_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 30rem;
  width: 22rem;
  background-color: white;
  box-shadow: 0rem 0.1875rem 0.375rem #00000029;
  border-radius: 3.5rem;
  cursor: pointer;
  transition: all 0.5s ease;
}

.card_box:nth-child(1) {
  margin-top: -9rem;
}

.card_box:nth-child(3) {
  margin-top: -9rem;
}

.card_box_hover:nth-child(1) {
  margin-top: -9rem;
}

.card_box_hover:nth-child(3) {
  margin-top: -9rem;
}

.card_box .card_img {
  margin-top: -6rem;
}

.card_box_hover .card_img {
  margin-top: -6rem;
}

.card_box .card_img img {
  max-width: 20rem;
  max-height: 15rem;
  height: auto;
  width: auto;
  transition: all 0.5s ease-in-out;
}

.card_box_hover .card_img img {
  max-width: 20rem;
  max-height: 15rem;
  height: auto;
  width: auto;
  scale: 1.15;
  transition: all 0.5s ease-in-out;
}

.card_box .card_heading {
  color: #707070;
  font-size: 1.5rem;
  margin: 0.5rem 0;
  font-family: "Lato", sans-serif;
  font-weight: 600;
}

.card_box_hover .card_heading {
  color: white;
  font-size: 1.5rem;
  margin: 0.5rem 0;
  font-family: "Lato", sans-serif;
  font-weight: 600;
}

.card_box .card_body {
  width: 18rem;
  height: auto;
  text-align: center;
  font-family: sans-serif;
  font-size: 1.1rem;
  line-height: 1.3rem;
  font-family: "Lato", sans-serif;
}

.card_box_hover .card_body {
  width: 18rem;
  height: auto;
  text-align: center;
  font-family: sans-serif;
  font-size: 1.1rem;
  line-height: 1.3rem;
  font-family: "Lato", sans-serif;
}

.card_box .card_bookdemo_button {
  position: absolute;
  bottom: 3%;
}

.card_box_hover .card_bookdemo_button {
  position: absolute;
  bottom: 3%;
}

/* /////////////////////////////////////////// shopping screen//////////////////////////////////////// */
.shopping_screen {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 4rem;
}

.shopping_screen .shopping_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.shopping_text .shopping_header_text {
  font-size: 3rem;
  color: #ccff00;
  width: 35rem;

  text-align: center;
}

.shopping_text .shopping_description {
  width: 46rem;
  color: white;
  font-size: 1.5rem;
  text-align: center;
}

.shopping_screen .shopping_gif_container {
  display: flex;
  flex-direction: column;

  align-items: center;
}

.shopping_button {
  color: black;
  display: inline-block;
  font-weight: 600;
  background-color: #eac404;
  text-align: center;
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  word-spacing: 0.2rem;
  transition: background-color 0.2s ease-out; /* Only transition background-color */
}

.shopping_button:hover {
  background-color: rgba(
    255,
    214,
    3,
    0.8
  ); /* Reduce the background opacity on hover */
}

/* /////////////////////////////////////////////// */
.schedule_demo_screen {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Lato", sans-serif;
}

.schedule_demo_screen .schedule_demo_container {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
}

.schedule_demo_container .left_design {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.left_design .left_curve {
  transform: rotate(-93deg);
}

.left_design img {
  width: auto;
  height: 10rem;
}

/* .left_design div::after {
  content: "";
  position: absolute;
  width: 1.2rem;
  height: 10rem;
  background-color: #071e4a;

  right: 0;
  top: 0;
} */

.schedule_demo_container .right_design {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.right_design .left_curve {
  transform: rotate(80deg);
  margin-bottom: 1.5rem;
}

.right_design img {
  width: auto;
  height: 10rem;
  transform: rotate(180deg);
}

.schedule_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.schedule_box .top_design {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: -4rem;
}
.top_design .left_curve {
  transform: rotate(-5deg);
  margin-right: 1rem;
  margin-bottom: 1.5rem;
}

.top_design img {
  width: auto;
  height: 10rem;
  transform: rotate(90deg);
}

.schedule_box .bottom_design {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -4rem;
}
.left_curve {
  background-color: transparent;
  border-color: #5bb646 transparent transparent transparent;
  border-radius: 100px 100px 0 0;
  width: 10.25rem;
  height: 5.25rem;
  border-radius: 100px 100px 0 0;
  border-style: solid;
  border-width: 5px;
}

.bottom_design img {
  width: auto;
  height: 10rem;
  transform: rotate(270deg);
  position: relative;
}
.bottom_design .left_curve {
  transform: rotate(172deg);
  margin-top: 1.5rem;
}

.schedule_box .schedule_text_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.schedule_text_container .text {
  font-size: 2rem;
  color: #ccff00;
  width: auto;

  max-width: 50rem;
  text-align: center;
}

.schedule_text_container .description {
  padding: 0 2rem;
  font-size: 1.5rem;
  color: white;
  width: auto;

  max-width: 50rem;
  text-align: center;
}

.boxeee {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  border: 5px solid transparent;
  border-right: 5px solid white;
  border-bottom: 5px solid white;
  -webkit-transform: rotate(-45deg);
}

/*///////////////////////////////// FONTS ///////////////////////////////////////*/
@font-face {
  font-family: "GoodTimes";
  src: local("GoodTimes"),
    url("../../fonts/good\ times\ rg.otf") format("opentype");
}

.GoodTimes {
  font-family: "GoodTimes";
}

@font-face {
  font-family: "SegoeUI";
  src: local("SegoeUI"), url("../../fonts/Segoe\ UI.ttf") format("truetype");
}

.SegoeUI {
  font-family: "SegoeUI";
}

/* //////////////////////////////////////////////////////////////////////// */

@media (min-width: 300px) and (max-width: 424px) {
  html,
  body {
    font-size: 6px;
  }

  .viewDemo_container {
    background-color: #024040;
  }
  .bike_group_90a {
    background-color: #024040;
    background-image: url(../../assets/mobilebg.png);
    background-repeat: no-repeat;
    background-size: 100vw 100%;
    overflow: hidden;
    -ms-overflow-style: none;
    background-position: center;
    scrollbar-width: none;
  }

  .viewDemo_container .bike_screen {
    position: relative;
    height: 80rem;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .viewDemo_content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    gap: 3rem;
    flex-direction: column;
  }

  .viewDemo_container .bike_screen .viewDemo_zdlogo {
    position: absolute;
    top: 3rem;
    left: 3rem;
    cursor: pointer;
  }

  .ViewDemo_heading {
    font-size: 2.875rem;
    width: 50rem;
    text-align: center;
    color: white;
    font-family: "Lato", sans-serif;
    font-weight: 600;
  }

  .viewDemogif_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
  }

  .viewDemo_gif img {
    display: block;
    max-width: 50rem;
    max-height: 60rem;
    width: auto;
    height: auto;
  }

  .viewDemo_button {
    width: 18rem;
    height: 4rem;
    display: flex;
    border-radius: 0.4375rem;
    align-items: center;
    justify-content: center;
    background-color: #0693ff;
    font-family: "Segoi UI";
    cursor: pointer;
    color: white;
    font-size: 1.5rem;
  }

  .shopping_screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .card_screen {
    height: auto;
  }

  .card_screen .cardScreen_heading {
    /* height: 45rem; */
    padding-bottom: 30rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    /* margin: -0rem 0 2rem 0; */

    font-size: 2.7rem;
    color: #ccff00;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    align-items: center;
  }

  .card_screen .card_container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11rem;
    margin-top: -10rem;
    flex-direction: column;
    padding-bottom: 5rem;
  }

  .card_container .card_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 45rem;
    width: 35rem;
    background-color: white;
    box-shadow: 0rem 0.1875rem 0.375rem #00000029;
    border-radius: 4.5rem;
  }

  .card_container .card_box_hover {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 45rem;
    width: 35rem;
    background-color: rgba(6, 6, 6, 0.45);
    outline: 1px solid #b4b4b4;
    box-shadow: 0rem 0.1875rem 0.375rem #00000029;
    border-radius: 4.5rem;
  }

  .card_box .card_heading {
    color: #707070;
    font-size: 2.5rem;
    margin: 0.8rem 0;
    font-family: "Lato", sans-serif;
    font-weight: 600;
  }

  .card_box_hover .card_heading {
    font-size: 2.5rem;
    margin: 0.8rem 0;
    font-family: "Lato", sans-serif;
    font-weight: 600;
  }

  .card_box .card_body {
    width: 28rem;
    height: auto;

    font-family: sans-serif;
    font-size: 1.8rem;
    line-height: 2.3rem;
    font-family: "Lato", sans-serif;
  }

  .card_box_hover .card_body {
    width: 28rem;
    height: auto;

    font-family: sans-serif;
    font-size: 1.8rem;
    line-height: 2.3rem;
    font-family: "Lato", sans-serif;
  }

  .card_box .card_img img {
    max-width: 35rem;
    max-height: 20rem;
    height: auto;
    width: auto;
  }

  .card_box_hover .card_img img {
    max-width: 35rem;
    max-height: 20rem;
    height: auto;
    width: auto;
  }

  .card_box:nth-child(1) {
    margin-top: 0rem;
  }

  .card_box:nth-child(3) {
    margin-top: 0rem;
  }

  .card_box .card_img {
    margin-top: -8rem;
  }

  .card_box_hover:nth-child(1) {
    margin-top: 0rem;
  }

  .card_box_hover:nth-child(3) {
    margin-top: 0rem;
  }

  .card_box_hover .card_img {
    margin-top: -8rem;
  }

  .card_box .card_bookdemo_button {
    position: absolute;
    bottom: 3%;
  }

  .card_box_hover .card_bookdemo_button {
    position: absolute;
    bottom: 3%;
  }
}

@media (min-width: 425px) and (max-width: 550px) {
  html,
  body {
    font-size: 7px;
  }

  .viewDemo_container {
    background-color: #024040;
  }
  .bike_group_90a {
    background-color: #024040;
    background-image: url(../../assets/mobilebg.png);
    background-repeat: no-repeat;
    background-size: 100vw 100%;
    overflow: hidden;
    -ms-overflow-style: none;
    background-position: center;
    scrollbar-width: none;
  }

  .shopping_screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .viewDemo_container .bike_screen {
    position: relative;
    height: 80rem;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .viewDemo_content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    gap: 3rem;
    flex-direction: column;
  }

  .viewDemo_container .bike_screen .viewDemo_zdlogo {
    position: absolute;
    top: 3rem;
    left: 3rem;
    cursor: pointer;
  }

  .ViewDemo_heading {
    font-size: 2.875rem;
    width: 50rem;
    text-align: center;
    color: white;
    font-family: "Lato", sans-serif;
    font-weight: 600;
  }

  .viewDemogif_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
  }

  .viewDemo_gif img {
    display: block;
    max-width: 50rem;
    max-height: 60rem;
    width: auto;
    height: auto;
  }

  .viewDemo_button {
    width: 18rem;
    height: 4rem;
    display: flex;
    border-radius: 0.4375rem;
    align-items: center;
    justify-content: center;
    background-color: #0693ff;
    font-family: "Segoi UI";
    cursor: pointer;
    color: white;
    font-size: 1.5rem;
  }

  .card_screen {
    height: auto;
  }

  .card_screen .cardScreen_heading {
    padding-bottom: 30rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    /* margin: -0rem 0 2rem 0; */

    font-size: 2.7rem;
    color: #ccff00;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card_screen .card_container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11rem;
    margin-top: -10rem;
    flex-direction: column;
    padding-bottom: 5rem;
  }

  .card_container .card_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 45rem;
    width: 35rem;
    background-color: white;
    box-shadow: 0rem 0.1875rem 0.375rem #00000029;
    border-radius: 4.5rem;
  }

  .card_container .card_box_hover {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 45rem;
    width: 35rem;
    outline: 1px solid #b4b4b4;
    background-color: rgba(4, 4, 4, 0.427);
    box-shadow: 0rem 0.1875rem 0.375rem #00000029;
    border-radius: 4.5rem;
  }

  .card_box .card_heading {
    color: #707070;
    font-size: 2.5rem;
    margin: 0.8rem 0;
    font-family: "Lato", sans-serif;
    font-weight: 600;
  }

  .card_box_hover .card_heading {
    font-size: 2.5rem;
    margin: 0.8rem 0;
    font-family: "Lato", sans-serif;
    font-weight: 600;
  }

  .card_box .card_body {
    width: 28rem;
    height: auto;

    font-family: sans-serif;
    font-size: 1.8rem;
    line-height: 2.3rem;
    font-family: "Lato", sans-serif;
  }

  .card_box_hover .card_body {
    width: 28rem;
    height: auto;

    font-family: sans-serif;
    font-size: 1.8rem;
    line-height: 2.3rem;
    font-family: "Lato", sans-serif;
  }

  .card_box .card_img img {
    max-width: 35rem;
    max-height: 20rem;
    height: auto;
    width: auto;
  }

  .card_box_hover .card_img img {
    max-width: 35rem;
    max-height: 20rem;
    height: auto;
    width: auto;
  }

  .card_box:nth-child(1) {
    margin-top: 0rem;
  }

  .card_box:nth-child(3) {
    margin-top: 0rem;
  }

  .card_box .card_img {
    margin-top: -8rem;
  }

  .card_box_hover:nth-child(1) {
    margin-top: 0rem;
  }

  .card_box_hover:nth-child(3) {
    margin-top: 0rem;
  }

  .card_box_hover .card_img {
    margin-top: -8rem;
  }

  .card_box .card_bookdemo_button {
    position: absolute;
    bottom: 3%;
  }

  .card_box_hover .card_bookdemo_button {
    position: absolute;
    bottom: 3%;
  }
}

@media (min-width: 551px) and (max-width: 675px) {
  html,
  body {
    font-size: 8px;
  }

  .viewDemo_container {
    background-color: #024040;
  }
  .bike_group_90a {
    background-color: #024040;
    background-image: url(../../assets/mobilebg.png);
    background-repeat: no-repeat;
    background-size: 100vw 100%;
    overflow: hidden;
    -ms-overflow-style: none;
    background-position: center;
    scrollbar-width: none;
  }

  .shopping_screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .viewDemo_container .bike_screen {
    position: relative;
    height: 80rem;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .viewDemo_content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    gap: 3rem;
    flex-direction: column;
  }

  .viewDemo_container .bike_screen .viewDemo_zdlogo {
    position: absolute;
    top: 3rem;
    left: 3rem;
  }

  .ViewDemo_heading {
    font-size: 2.875rem;
    width: 50rem;
    text-align: center;
    color: white;
    font-family: "Lato", sans-serif;
    font-weight: 600;
  }

  .viewDemogif_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
  }

  .viewDemo_gif img {
    display: block;
    max-width: 50rem;
    max-height: 60rem;
    width: auto;
    height: auto;
  }

  .viewDemo_button {
    width: 18rem;
    height: 4rem;
    display: flex;
    border-radius: 0.4375rem;
    align-items: center;
    justify-content: center;
    background-color: #0693ff;
    font-family: "Segoi UI";
    cursor: pointer;
    color: white;
    font-size: 1.5rem;
  }

  .card_screen {
    height: auto;
  }

  .card_screen .cardScreen_heading {
    padding-bottom: 30rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    /* margin: -0rem 0 2rem 0; */

    font-size: 2.7rem;
    color: #ccff00;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card_screen .card_container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11rem;
    margin-top: -10rem;
    flex-direction: column;
    padding-bottom: 5rem;
  }

  .card_container .card_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 45rem;
    width: 35rem;
    background-color: white;
    box-shadow: 0rem 0.1875rem 0.375rem #00000029;
    border-radius: 4.5rem;
  }

  .card_container .card_box_hover {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 45rem;
    width: 35rem;
    outline: 1px solid #b4b4b4;
    background-color: rgba(0, 0, 0, 0.409);
    box-shadow: 0rem 0.1875rem 0.375rem #00000029;
    border-radius: 4.5rem;
  }

  .card_box .card_heading {
    color: #707070;
    font-size: 2.5rem;
    margin: 0.8rem 0;
    font-family: "Lato", sans-serif;
    font-weight: 600;
  }

  .card_box_hover .card_heading {
    font-size: 2.5rem;
    margin: 0.8rem 0;
    font-family: "Lato", sans-serif;
    font-weight: 600;
  }

  .card_box .card_body {
    width: 28rem;
    height: auto;

    font-family: sans-serif;
    font-size: 1.8rem;
    line-height: 2.3rem;
    font-family: "Lato", sans-serif;
  }

  .card_box_hover .card_body {
    width: 28rem;
    height: auto;

    font-family: sans-serif;
    font-size: 1.8rem;
    line-height: 2.3rem;
    font-family: "Lato", sans-serif;
  }

  .card_box .card_img img {
    max-width: 35rem;
    max-height: 20rem;
    height: auto;
    width: auto;
  }

  .card_box_hover .card_img img {
    max-width: 35rem;
    max-height: 20rem;
    height: auto;
    width: auto;
  }

  .card_box:nth-child(1) {
    margin-top: 0rem;
  }

  .card_box:nth-child(3) {
    margin-top: 0rem;
  }

  .card_box .card_img {
    margin-top: -8rem;
  }

  .card_box_hover:nth-child(1) {
    margin-top: 0rem;
  }

  .card_box_hover:nth-child(3) {
    margin-top: 0rem;
  }

  .card_box_hover .card_img {
    margin-top: -8rem;
  }

  .card_box .card_bookdemo_button {
    position: absolute;
    bottom: 3%;
  }

  .card_box_hover .card_bookdemo_button {
    position: absolute;
    bottom: 3%;
  }
}

@media (min-width: 676px) and (max-width: 799px) {
  html,
  body {
    font-size: 9px;
  }

  .viewDemo_container {
    background-color: #024040;
  }
  .bike_group_90a {
    background-color: #024040;
    background-image: url(../../assets/mobilebg.png);
    background-repeat: no-repeat;
    background-size: 100vw 100%;
    overflow: hidden;
    -ms-overflow-style: none;
    background-position: center;
    scrollbar-width: none;
  }
  .shopping_screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .viewDemo_container .bike_screen {
    position: relative;
    height: 80rem;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .viewDemo_content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    gap: 3rem;
    flex-direction: column;
  }

  .viewDemo_container .bike_screen .viewDemo_zdlogo {
    position: absolute;
    top: 3rem;
    left: 3rem;
  }

  .ViewDemo_heading {
    font-size: 2.875rem;
    width: 50rem;
    text-align: center;
    color: white;
    font-family: "Lato", sans-serif;
    font-weight: 600;
  }

  .viewDemogif_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
  }

  .viewDemo_gif img {
    display: block;
    max-width: 50rem;
    max-height: 60rem;
    width: auto;
    height: auto;
  }

  .viewDemo_button {
    width: 18rem;
    height: 4rem;
    display: flex;
    border-radius: 0.4375rem;
    align-items: center;
    justify-content: center;
    background-color: #0693ff;
    font-family: "Segoi UI";
    cursor: pointer;
    color: white;
    font-size: 1.5rem;
  }

  .card_screen {
    height: auto;
  }

  .card_screen .cardScreen_heading {
    padding-bottom: 30rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    /* margin: -0rem 0 2rem 0; */

    font-size: 2.7rem;
    color: #ccff00;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card_screen .card_container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11rem;
    margin-top: -10rem;
    flex-direction: column;
    padding-bottom: 5rem;
  }

  .card_container .card_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 45rem;
    width: 35rem;
    background-color: white;
    box-shadow: 0rem 0.1875rem 0.375rem #00000029;
    border-radius: 4.5rem;
  }

  .card_container .card_box_hover {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 45rem;
    width: 35rem;
    outline: 1px solid #b4b4b4;
    background-color: rgba(0, 0, 0, 0.45);
    box-shadow: 0rem 0.1875rem 0.375rem #00000029;
    border-radius: 4.5rem;
  }

  .card_box .card_heading {
    color: #707070;
    font-size: 2.5rem;
    margin: 0.8rem 0;
    font-family: "Lato", sans-serif;
    font-weight: 600;
  }

  .card_box_hover .card_heading {
    font-size: 2.5rem;
    margin: 0.8rem 0;
    font-family: "Lato", sans-serif;
    font-weight: 600;
  }

  .card_box .card_body {
    width: 28rem;
    height: auto;

    font-family: sans-serif;
    font-size: 1.8rem;
    line-height: 2.3rem;
    font-family: "Lato", sans-serif;
  }

  .card_box_hover .card_body {
    width: 28rem;
    height: auto;

    font-family: sans-serif;
    font-size: 2.1rem;
    line-height: 1.8rem;
    font-family: "Lato", sans-serif;
  }

  .card_box .card_img img {
    max-width: 35rem;
    max-height: 20rem;
    height: auto;
    width: auto;
  }

  .card_box_hover .card_img img {
    max-width: 35rem;
    max-height: 20rem;
    height: auto;
    width: auto;
  }

  .card_box:nth-child(1) {
    margin-top: 0rem;
  }

  .card_box:nth-child(3) {
    margin-top: 0rem;
  }

  .card_box .card_img {
    margin-top: -8rem;
  }

  .card_box_hover:nth-child(1) {
    margin-top: 0rem;
  }

  .card_box_hover:nth-child(3) {
    margin-top: 0rem;
  }

  .card_box_hover .card_img {
    margin-top: -8rem;
  }

  .card_box .card_bookdemo_button {
    position: absolute;
    bottom: 3%;
  }

  .card_box_hover .card_bookdemo_button {
    position: absolute;
    bottom: 3%;
  }
}

@media (min-width: 800px) and (max-width: 1024px) {
  html,
  body {
    font-size: 10px;
  }

  .viewDemo_container {
    background-color: #024040;
  }
  .bike_group_90a {
    background-color: #024040;
    background-image: url(../../assets/mobilebg.png);
    background-repeat: no-repeat;
    background-size: 100vw 100%;
    overflow: hidden;
    -ms-overflow-style: none;
    background-position: center;
    scrollbar-width: none;
  }

  .shopping_screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .viewDemo_container .bike_screen {
    position: relative;
    height: 80rem;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .viewDemo_content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    gap: 3rem;
    flex-direction: column;
  }

  .viewDemo_container .bike_screen .viewDemo_zdlogo {
    position: absolute;
    top: 3rem;
    left: 3rem;
  }

  .ViewDemo_heading {
    font-size: 2.875rem;
    width: 50rem;
    text-align: center;
    color: white;
    font-family: "Lato", sans-serif;
    font-weight: 600;
  }

  .viewDemogif_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
  }

  .viewDemo_gif img {
    display: block;
    max-width: 60rem;
    max-height: 60rem;
    width: auto;
    height: auto;
  }

  .viewDemo_button {
    width: 18rem;
    height: 4rem;
    display: flex;
    border-radius: 0.4375rem;
    align-items: center;
    justify-content: center;
    background-color: #0693ff;
    font-family: "Segoi UI";
    cursor: pointer;
    color: white;
    font-size: 1.5rem;
  }

  .card_screen {
    height: auto;
  }

  .card_screen .cardScreen_heading {
    padding-bottom: 30rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    /* margin: -0rem 0 2rem 0; */

    font-size: 2.7rem;
    color: #ccff00;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card_screen .card_container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11rem;
    margin-top: -10rem;
    flex-direction: column;
    padding-bottom: 5rem;
  }

  .card_container .card_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 45rem;
    width: 35rem;
    background-color: white;
    box-shadow: 0rem 0.1875rem 0.375rem #00000029;
    border-radius: 4.5rem;
  }

  .card_container .card_box_hover {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 45rem;
    width: 35rem;
    background-color: rgba(0, 0, 0, 0.384);
    box-shadow: 0rem 0.1875rem 0.375rem #00000029;
    border-radius: 4.5rem;
  }

  .card_box .card_heading {
    color: #707070;
    font-size: 2.5rem;
    margin: 0.8rem 0;
    font-family: "Lato", sans-serif;
    font-weight: 600;
  }

  .card_box_hover .card_heading {
    font-size: 2.5rem;
    margin: 0.8rem 0;
    font-family: "Lato", sans-serif;
    font-weight: 600;
  }

  .card_box .card_body {
    width: 28rem;
    height: auto;

    font-family: sans-serif;
    font-size: 1.8rem;
    line-height: 2.3rem;
    font-family: "Lato", sans-serif;
  }

  .card_box_hover .card_body {
    width: 28rem;
    height: auto;

    font-family: sans-serif;
    font-size: 1.8rem;
    line-height: 2.3rem;
    font-family: "Lato", sans-serif;
  }

  .card_box .card_img img {
    max-width: 35rem;
    max-height: 20rem;
    height: auto;
    width: auto;
  }

  .card_box_hover .card_img img {
    max-width: 35rem;
    max-height: 20rem;
    height: auto;
    width: auto;
  }

  .card_box:nth-child(1) {
    margin-top: 0rem;
  }

  .card_box:nth-child(3) {
    margin-top: 0rem;
  }

  .card_box .card_img {
    margin-top: -8rem;
  }

  .card_box_hover:nth-child(1) {
    margin-top: 0rem;
  }

  .card_box_hover:nth-child(3) {
    margin-top: 0rem;
  }

  .card_box_hover .card_img {
    margin-top: -8rem;
  }

  .card_box .card_bookdemo_button {
    position: absolute;
    bottom: 3%;
  }

  .card_box_hover .card_bookdemo_button {
    position: absolute;
    bottom: 3%;
  }
}

/*///////////////////////////////// MEDIA QUERIES ///////////////////////////////////////*/

@media (min-width: 1025px) and (max-width: 1280px) {
  html,
  body {
    font-size: 10px;
  }
}

@media (min-width: 1281px) and (max-width: 1536px) {
  html,
  body {
    font-size: 12px;
  }
}

@media (min-width: 1537px) and (max-width: 1792px) {
  html,
  body {
    font-size: 14px;
  }
}

@media (min-width: 1793px) and (max-width: 2048px) {
  html,
  body {
    font-size: 16px;
  }
}

@media (min-width: 2049px) and (max-width: 2560px) {
  html,
  body {
    font-size: 18px;
  }
}

@media (min-width: 2561px) and (max-width: 3840px) {
  html,
  body {
    font-size: 20px;
  }
}
