.performance_container {
  width: 100dvw;
  height: 100dvh;
  overflow-y: auto;
  background-color: #f7f7f7;
  padding: 3rem;
}
.performance_subheading {
  font-size: 1.5rem;
  font-weight: 600;
  margin-left: 3rem;
  margin-bottom: 2rem;
}
.performance_body_container {
  width: 100%;
  padding: 0rem 3rem;
}
.performance_item {
  width: 100%;
  padding: 2rem;
  border: 1px solid #38be09;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}
.performance_dropdown {
  padding: 0.8rem;
  border: 1px solid #1a1b37;
  width: 35rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.8rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 0rem;
}
.performance_item_title {
  font-size: 1rem;
  max-width: 25rem;
  width: auto;
}
.performance_dropdown img {
  width: 2rem;
  height: 2rem;
}
.performance_dropdown .performance_dropdown_menu {
  position: absolute;
  top: 3.7rem;
  width: 100%;
  background-color: white;
  z-index: 10;
  left: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}
.performance_dropdown_menu_item {
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
}
.performance_dropdown_menu_item:hover {
  background-color: #d7d2d2;
}

.perfomance_graph_container {
  width: 100%;
  padding-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.graph_box {
  width: 50%;
  height: 25rem;
}
