.dashboard_container {
  width: 100dvw;
  height: 100dvh;
  background-color: #f7f7f7;
  overflow-y: auto;
  padding: 3rem 6.5rem 3rem 6.5rem;
}
.dashboard_heading {
  font-size: 2.5rem;
  color: black;
  margin-bottom: 3.5rem;
  font-weight: 600;
}
.dashboard_highest_score {
  width: 100%;

  text-align: end;
  font-size: 2rem;
}
.dashboard_item_container {
  margin-top: 5rem;
  width: 100%;

  gap: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dashboard_item {
  width: 100%;
  padding: 2rem;
  border: 1px solid #1a1b37;
  border-radius: 1rem;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}
.dashboard_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard_title_group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.dashboard_title {
  font-size: 1.8rem;
}
.dashboard_title_group img {
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
}
.average_score_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
  gap: 1rem;
  flex-direction: row-reverse;
}
