.userdashboard_highest_score {
  width: 100%;

  text-align: start;
  font-size: 2rem;
}
.userDashboard_item {
  width: 100%;
  padding: 2rem;
  border: 1px solid #38be09;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.userDashboard_title_group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.userDashboard_title {
  color: #000000;
  font-size: 1.875rem;
}
.userDashboard_score_group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  flex-direction: row-reverse;
}
.userDashboard_score_group img {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
.userDashboard_info_container {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
}
.userDashboard_info_item {
  padding: 1.5rem 2rem;
  border: 1px solid #707070;
  width: 100%;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.userDashboard_flex_group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}
.userDashboard_hyperlink {
  margin-top: 0.2rem;
  color: #00429d;
  cursor: pointer;
  font-size: 1rem;
}
.userDashboard_hyperlink:hover {
  text-decoration: underline;
}
.userDashboard_no_result {
  font-size: 1.5rem;
}
.lms_loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #3498db;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.userdashboard_heading {
  font-size: 2rem;
  font-weight: 600;
  align-self: flex-start;
}
.userDashboard_heading_group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.performance_button {
  padding: 0.7rem 1.3rem;
  background-color: #1a1b37;
  color: #fff;
  border: 1px solid #1a1b37;
  border-radius: 2rem;
  cursor: pointer;
}
