.view_details_button {
  color: rgb(1, 112, 185);
  font-size: 1rem;
  cursor: pointer;
}
.view_details_button:hover {
  text-decoration: underline;
}

.lms_admin_delete_button {
  background-color: #f00e0e;
  padding: 0.5rem 1rem;
  color: white;
  border-radius: 0.4rem;
  margin-left: 1rem;
  cursor: pointer;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.lms_admin_delete_button:hover {
  background-color: #de2c2c;
}

.lms_edit_user img {
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
}
.dashboard_title_name {
  font-size: 1.5rem;
  width: 7rem;
}
