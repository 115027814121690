.productsPage_container {
  margin: 2rem auto;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  justify-content: space-evenly;
  gap: 2rem;
  padding: 0 10rem;
}

.style_product_card {
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.4rem;
  cursor: pointer;
  position: relative;
  padding: 1rem;
  height: 40rem;
}
.style_product_image_container {
  height: 30rem;
  width: 20rem;
  overflow: hidden;
  position: relative;
}
.style_product_image_container img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  transition: all 0.3s ease-in-out;
}
.style_product_image_container img:hover {
  scale: 1.1;
  transition: all 0.3s ease-in-out;
}

.style_product_name {
  font-size: 1.5rem;
  font-weight: 400;
  width: 17rem;
  text-align: center;
}
