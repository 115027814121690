@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
}

body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url(../../assets/wp-bg-low.png) url(../../assets/mobile-bg.png)
    url(../../assets/circle-bg.png) url(../../assets/wp-bg-low.png);
}

.edge {
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100%;
  width: 100%;
  /* background-color: #000821; */
  background-size: cover;
  background-repeat: no-repeat;
  /* background-image: url("../../assets/wp-bg-low.png"); */
}

.edge::-webkit-scrollbar {
  display: none;
}

.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.logo-icon {
  margin: 2.6875rem 0.3125rem 0 4.3125rem;
  display: block;
  max-width: 12.75rem;
  max-height: 3.9375rem;
  width: auto;
  height: auto;
}

.coming-soon {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  max-width: 14rem;
  max-height: 7rem;
  width: auto;
  height: auto;
  margin: 3rem;
}

.model-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 4rem 5rem 4rem 5rem;
}

.modal-bg {
  width: 100vw;
  height: 56vw;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;
  z-index: 2;
  position: sticky;
  padding: 1rem;
}
.demo_page {
  position: absolute;
  right: 5rem;
  font-family: "Lato", sans-serif;
  top: 2rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: #042250;
  font-weight: 600;
}

.model-components {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 4rem 0 0 13rem;
}

.textarea {
  width: 30rem;
  min-height: 2.375rem;
  padding: 0.8rem;
  font-size: 1.3125rem;
  color: #707070;
  font-family: "Lato", sans-serif;
  font-style: medium;
  border: 0.0625rem solid #707070;
  border-radius: 0.5125rem;
  opacity: 1;
  cursor: pointer;
  outline: none;
  overflow: auto;
  resize: none;
}

.header-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.model-header {
  text-align: center;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-size: 4.6875rem;
  font-weight: 900;
  letter-spacing: 0.01rem;
  color: #042250;
  opacity: 1;
  width: 32.5625rem;
}

.model-header2 {
  text-align: center;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-size: 3.125rem;
  font-weight: 900;
  letter-spacing: 0.01rem;
  color: #042250;
  width: 24.0625rem;
  opacity: 1;
}

.model-header3 {
  text-align: center;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-size: 2.5rem;
  font-weight: 900;
  letter-spacing: 0.01rem;
  color: #042250;
  width: 20.375rem;
  opacity: 1;
}

.model-text {
  margin: 2rem 0rem 0 0;
  text-align: left;
  font-style: normal;
  font-size: 1.51rem;
  font-family: "Lato", sans-serif;
  letter-spacing: 0rem;
  color: #03193b;
  opacity: 1;
  width: 35rem;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  align-self: center;
  width: 35rem;
  margin: 3rem 0 0 0;
}

.request-demo-btn {
  width: 12.625rem;
  height: 3.5625rem;
  border: none;
  /* margin: 3rem 1rem 0 3rem; */
  padding: 1.2rem;
  border-radius: 0.6875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Lato", sans-serif;
  font-style: medium;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 0rem;
  opacity: 1;
  cursor: pointer;
  z-index: 4;
}

.popup-header {
  font-family: "Lato", sans-serif;
  font-style: medium;
  font-size: 1.5625rem;
  letter-spacing: 0rem;
  color: white;
  opacity: 1;
  margin: 3.4375rem 0 1.75rem 0;
}

.user-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 0.5rem 0 1.2rem 0;
  width: 70%;
}

.mobile-num-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mobile-num-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.user-text {
  font-family: "Lato", sans-serif;
  font-style: medium;
  font-size: 1.5625rem;
  font-weight: 500;
  letter-spacing: 0rem;
  color: white;
}

/* .user-input {
  width: 21rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-family: "Lato", sans-serif;
  font-style: medium;
  font-size: 1.3125rem;
  font-weight: 500;
  letter-spacing: 0rem;
  color: #707070;
  border: 0.0625rem solid #707070;
  border-radius: 1.8125rem;
  opacity: 1;
  cursor: pointer;
} */

.user-input {
  width: 30rem;
  padding: 0.6rem;
  font-family: "Lato", sans-serif;
  font-style: medium;
  font-size: 1.3125rem;
  font-weight: 500;
  letter-spacing: 0rem;
  color: #707070;
  border: 0.0625rem solid #707070;
  border-radius: 0.5125rem;
  opacity: 1;
  cursor: pointer;
}

.user-input:focus {
  outline: none;
}

.flexbox5 {
  display: flex;
  flex-direction: column;
}

.user-input1 {
  width: 21.5rem;
  padding: 0.6rem;
  font-family: "Lato", sans-serif;
  font-style: medium;
  font-size: 1.3125rem;
  font-weight: 500;
  letter-spacing: 0rem;
  color: #707070;
  border: 0.0625rem solid #707070;
  border-radius: 0.5125rem;
  opacity: 1;
  cursor: pointer;
}

.user-input1:focus {
  outline: none;
}

.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Lato", sans-serif;
  font-style: medium;
  font-size: 1.2rem;
  letter-spacing: 0rem;
  color: #ffffff;
  background: #0133a1 0% 0% no-repeat padding-box;
  box-shadow: inset 0rem 0.1875rem 0.375rem #00000029,
    0rem 0.1875rem 0.375rem #00000029;
  border-radius: 0.4375rem;
  opacity: 1;
  padding: 0.5625rem 3.125rem 0.5625rem 3.125rem;
  margin: 1rem 2rem 2rem 2rem;
  cursor: pointer;
}

.input-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.input-container1 {
  display: flex;
  align-items: center;
  gap: 1.5625rem;
}

.error-msg {
  color: #ff0000;
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-bottom: -0.6rem;
}

.close-icon {
  position: absolute;
  right: 1.6rem;
  top: 0.5rem;
  cursor: pointer;
  border: none;
}

.close-icon img {
  display: block;
  max-width: 5rem;
  max-height: 5rem;
  width: auto;
  height: auto;
  padding: 0.5rem;
}

.iframe {
  width: 55vw;
  height: 40rem;
  border: none;
  padding: 0 1rem 1rem 0;
}

.multi-iframe {
  width: 100vw;
  height: 75vh;
  border: none;
  margin: 0 2rem 0 2rem;
}

.video-container {
  max-width: 50vw;
  background-color: transparent;
  align-items: center;
  border: none;
}

.video-player {
  width: 47.3rem;
  height: 29.75rem;
  background-color: rgba(0, 0, 0, 0.992);
}

.features {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 8rem 0 8rem;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 1;
}

.features-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 1rem 0 0rem 0;
}

.header {
  text-align: center;
  color: #ffffff;
  font-size: 2.5rem;
  font-family: "Lato", sans-serif;
  font-style: normal;
  letter-spacing: 0rem;
  opacity: 1;
}

.feature-text-container {
  flex: 1;
}

.features-img {
  display: block;
  max-width: 50rem;
  max-height: 50rem;
  width: auto;
  height: auto;
}

.feature-desc {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: 4rem 0 0 0;
}

.desc {
  margin: 1.5rem 2rem 1.5rem 0rem;
  /* width: 80%; */
  color: #ffff;
  text-align: left;
  font-size: 1.6rem;
  font-family: "Lato", sans-serif;
  font-style: normal;
}

.user-guide-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  padding: 5rem 0 8rem 0;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 1;
}

.guide-items {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}

.upload-model {
  height: 34rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  box-shadow: 0rem 0.1875rem 0.375rem #00000029;
  opacity: 1;
}

.upload-model:hover {
  background: #062a57 0% 0% no-repeat padding-box;
}

.upload-model-img {
  display: flex;
  max-width: 3.125rem;
  max-height: 3.125rem;
  width: auto;
  height: auto;
  margin: 2.625rem;
}

.upload-model-title {
  text-align: center;
  font-family: "Lato", sans-serif;
  padding: 0.5rem 1rem 2rem 1rem;
  font-size: 2rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0rem;
  color: #ffff;
  opacity: 1;
}

.upload-model-text {
  width: 28rem;
  text-align: left;
  font-family: "Lato", sans-serif;
  padding: 0rem 3rem 3rem 3rem;
  font-size: 1.5rem;
  font-style: normal;
  letter-spacing: 0rem;
  color: #ffff;
  opacity: 1;
}

/* /////////---GUIDE border----/////////// */

.border-with-cuts {
  position: relative;
  color: white;
  margin: 3rem;
}

/* Path: box1 */

.top::before {
  content: "";
  position: absolute;
  top: 0;
  clip-path: polygon(0 0, 95% 0, 100% 100%, 0 100%);
  width: 85%;
  height: 0.75rem;
  background-color: #0098ff;
}

.top::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 100%);
  width: 10%;
  height: 0.75rem;
  background-color: #0098ff;
}

.left::before {
  content: "";
  position: absolute;
  left: 0;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 95%);
  width: 0.75rem;
  height: 60%;
  background-color: #0098ff;
}

.right::before {
  content: "";
  position: absolute;
  right: 0;
  clip-path: polygon(0 0, 100% 4%, 100% 100%, 0 100%);
  bottom: 0;
  width: 0.75rem;
  height: 85%;
  background-color: #0098ff;
}

.right::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
  width: 0.75rem;
  height: 10%;
  background-color: #0098ff;
}

.bottom::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0.75rem;
  background-color: #0098ff;
}

.bottom::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border: inherit;
  clip-path: polygon(0 0, 100% 20%, 100% 100%, 0 100%);
  width: 0.75rem;
  height: 20%;
  background-color: #0098ff;
}

/* Path: box2 */

.top1::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 0.75rem;
  background-color: #0098ff;
}

.right1::before {
  content: "";
  position: absolute;
  right: 0;
  width: 0.75rem;
  height: 85%;
  background-color: #0098ff;
  clip-path: polygon(0 0, 100% 0, 100% 95%, 0 100%);
}

.right1::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0.75rem;
  height: 10%;
  background-color: #0098ff;
  clip-path: polygon(0 30%, 100% 0, 100% 100%, 0 100%);
}

.bottom1::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 80%;
  height: 0.75rem;
  background-color: #0098ff;
  clip-path: polygon(0 0, 100% 0, 95% 100%, 0 100%);
}

.bottom1::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10%;
  height: 0.75rem;
  background-color: #0098ff;
  clip-path: polygon(30% 0, 100% 0, 100% 100%, 0 100%);
}

.left1::before {
  content: "";
  position: absolute;
  left: 0;
  width: 0.75rem;
  height: 25%;
  background-color: #0098ff;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
}

.left1::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0.75rem;
  height: 55%;
  background-color: #0098ff;
  clip-path: polygon(0 7%, 100% 0, 100% 100%, 0 100%);
}

/* Path: box3 */

.top2::before {
  content: "";
  position: absolute;
  top: 0;
  width: 10%;
  height: 0.75rem;
  background-color: #0098ff;
  clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%);
}

.top2::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 80%;
  height: 0.75rem;
  background-color: #0098ff;
  clip-path: polygon(5% 0, 100% 0, 100% 100%, 0 100%);
}

.right2::before {
  content: "";
  position: absolute;
  right: 0;
  width: 0.75rem;
  height: 55%;
  background-color: #0098ff;
  clip-path: polygon(0 0, 100% 0, 100% 92%, 0 100%);
}

.right2::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0.75rem;
  height: 30%;
  background-color: #0098ff;
  clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
}

.bottom2::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0.75rem;
  background-color: #0098ff;
}

.left2::before {
  content: "";
  position: absolute;
  left: 0;
  width: 0.75rem;
  height: 10%;
  background-color: #0098ff;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
}

.left2::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0.75rem;
  height: 80%;
  background-color: #0098ff;
  clip-path: polygon(0 4%, 100% 0, 100% 100%, 0 100%);
}

/* //////////////////// */

.partner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  opacity: 1;
}

.partner-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 5rem;
  width: 100vw;
  padding: 3rem 3rem 3rem 3rem;
  background: #33394d 0% 0% no-repeat padding-box;
  opacity: 1;
}

.partner-model {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16.25rem;
  height: 11.0625rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0rem 0.1875rem 0.375rem #00000029;
  border-radius: 1.0625rem;
  opacity: 1;
}

.partner-model-img {
  display: flex;
  max-width: 12rem;
  max-height: 12rem;
  width: auto;
  height: auto;
}

/* //////////////// */
.our-tech {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 8rem 0 8rem;
  width: 100vw;
  opacity: 1;
}

.our-tech-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 1rem 0 -5rem 0;
}

.tech-image {
  display: block;
  max-width: 54vw;
  max-height: 54vw;
  width: auto;
  height: auto;
}

.our-tech-text-container {
  flex: 1;
}

.our-tech-desc {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: 4rem 0rem 0 0rem;
}

:root {
  --background-dark: #2d3548;
  --text-light: rgba(255, 255, 255, 0.6);
  --text-lighter: rgba(255, 255, 255, 0.9);
  --spacing-s: 0.5rem;
  --spacing-m: 1rem;
  --spacing-l: 1.5rem;
  --spacing-xl: 2rem;
  --spacing-xxl: 4rem;
  --width-container: 75rem;
}

.hero-section {
  align-items: flex-start;
  background-color: darkturquoise;
  display: flex;
  min-height: 100%;
  width: 100vw;
  justify-content: center;
  padding: var(--spacing-xxl) var(--spacing-l);
}

.cgrid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  max-width: 90rem;
  width: 100vw;
  margin-top: 5rem;
}

@media (min-width: 320px) {
  .cgrid {
    grid-template-columns: repeat(1, 1fr);
    max-width: 25rem;
  }
}

@media (min-width: 540px) {
  .cgrid {
    grid-template-columns: repeat(2, 1fr);
    max-width: 52rem;
  }
}

@media (min-width: 960px) {
  .cgrid {
    grid-template-columns: repeat(3, 1fr);
    max-width: 80rem;
  }
}

.card {
  list-style: none;
  position: relative;
}

.card:before {
  content: "";
  display: block;
  padding-bottom: 150%;
  width: 100%;
}

.cardbg {
  background-color: #1a3554;
  background-size: cover;
  background-position: center;
  border-style: solid;
  border-width: 0.25rem;
  border-color: #0a79ca;
  border-radius: 0.8125rem;
  bottom: 0;
  filter: brightness(0.75) saturate(1.2) contrast(0.85);
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: center;
  trsnsform: scale(1) translateZ(0);
  transition: filter 200ms linear, transform 200ms linear;
  opacity: 1;
}

.card:hover .cardbg {
  transform: scale(1.1) translateZ(0);
}

.ccontent {
  left: 0;
  position: absolute;
  top: 0;
}

.textcard {
  color: var(--text-lighter);
  font-size: 1.9rem;
  text-shadow: 0.125rem 0.125rem 1.25rem rgba(0, 0, 0, 0.2);
  line-height: 1.4;
  word-spacing: 100vw;
}

.card-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 2rem;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 1;
}

.choosing-us-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;
}

.choosing-us {
  display: block;
  max-width: 85vw;
  max-height: 45vw;
  width: auto;
  height: auto;
  margin: 5rem 0 5rem 0;
  opacity: 1;
}

.benifits-model {
  width: 31.0625rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background: #1a3554 0% 0% no-repeat padding-box;
  border-style: solid;
  border-color: #0a79ca;
  border-width: 0.25rem;
  border-radius: 0.8125rem;
  opacity: 1;
  margin: 0 0 3.5rem 0;
  padding: 0;
}

.benifits-model:hover {
  padding: 2rem 4rem 2rem 4rem;
  margin: 0;
}

.benifits-model-title {
  text-align: center;
  font-family: "Lato", sans-serif;
  padding: 2rem 1rem 2rem 1rem;
  font-size: 2rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0rem;
  color: #ffff;
  opacity: 1;
}

.benifits-model-text {
  max-width: 80rem;
  text-align: left;
  font-family: "Lato", sans-serif;
  padding: 0rem 3rem 3rem 0rem;
  font-size: 1.5rem;
  font-style: normal;
  letter-spacing: 0rem;
  color: #ffff;
  opacity: 1;
}

.bullet-point {
  list-style-type: square;

  /* list-style-image: url("../../assets/bullet.png"); */
  /* list-style-position: 2rem 4rem;
  list-style: outside;
  list-style-type: square; */

  /* list-style-type: none;
  background: url("../../assets/bullet.png") no-repeat;
  padding-left: 1rem;
  background-position: 0rem 0.4rem;
  background-size: 0.9rem 0.9rem; */
}

.start {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8rem 0rem 13rem 0rem;
  margin: 8rem 0 0 0;
  width: 100vw;
  background-size: 60vw 35vw;
  background-repeat: no-repeat;
  background-image: url("../../assets/circle-bg.png");
  background-position: 48.5%;
  opacity: 1;
}

.start-container {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.start-text {
  margin: 2rem 2rem 2rem 2rem;
  width: 36rem;
  color: #ffff;
  text-align: center;
  font-size: 1.875rem;
  font-family: "Lato", sans-serif;
  font-style: normal;
}

.tech-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 0 5rem 0;
  background-color: #021a3b;
}

.tech-items {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}

.tech {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tech-img {
  display: flex;
  max-width: 26rem;
  max-height: 26rem;
  width: auto;
  height: auto;
}

.tect-title {
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0rem;
  color: #606469;
  opacity: 1;
}

.platform-bg {
  padding: 4rem;
}

.platform-container {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.platform {
  padding-top: 3rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.platform-header {
  font-family: "Lato", sans-serif;
  font-size: 2rem;
  font-style: normal;
  letter-spacing: 0rem;
  color: #ffff;
  width: 100%;
}

.platform-text {
  font-family: "Lato", sans-serif;
  font-size: 1.6rem;
  font-style: normal;
  letter-spacing: 0rem;
  color: #ffff;
  padding-top: 1rem;
  z-index: 1;
}

.platform-img {
  display: flex;
  max-width: 35rem;
  max-height: 35rem;
  width: auto;
  height: auto;
}

/* .footer-container {
  position: relative;
  padding: 5rem 1rem 2rem 1rem;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;
} */

.footer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
}

.footer-logo-container {
  width: 40%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.footer-logo {
  margin: 9rem 8rem 0rem 8rem;
  display: flex;
  max-width: 16.4375rem;
  max-height: 17.75rem;
  width: auto;
  height: auto;
  opacity: 1;
}

.contact-us-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40%;
  position: relative;
}

.contact-us {
  margin: 4rem 3rem 2rem 8rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.footer-header {
  width: 24rem;
  font-family: "Lato", sans-serif;
  font-size: 1.8rem;
  font-style: normal;
  letter-spacing: 0rem;
  color: #ffffff;
  opacity: 1;
}

.contact-us-items {
  display: flex;
  align-items: center;
}

.contact-us-items-img {
  display: flex;
  max-width: 1.5rem;
  max-height: 2.375rem;
  width: auto;
  height: auto;
}

.contact-us-items-text {
  width: 20rem;
  font-family: "Lato", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  letter-spacing: 0rem;
  color: #ffffff;
  padding: 1rem;
}

.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 1rem;
}

.footer-bottom-text {
  font-family: "Lato", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  letter-spacing: 0rem;
  color: #ffffff;
  opacity: 1;
  padding: 0.9375rem 0.9375rem 0 0.9375rem;
}

.footer-bottom-img {
  display: block;
  max-width: 10.5625rem;
  max-height: 2.9375rem;
  width: auto;
  height: auto;
  margin-top: 0.9375rem;
  cursor: pointer;
}

.iframe-container {
  /* background-size: 42vw;
  background-position: bottom;
  background-repeat: no-repeat;
  background-image: url("../../assets/model-bg.png"); */
  /* background-color: aqua; */
  margin-top: -7rem;
}

/* ////////////---Dropdown---/////////////// */

.dropdown {
  position: relative;
  display: inline-block;
}

.selected-option {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.2rem 0.3rem 0.2rem 0.3rem;
  background-color: white;
  border: 0.0625rem solid #ccc;
  border-radius: 0.35rem;
  cursor: pointer;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
}

.selected-option img {
  width: 2.7rem;
  height: 2rem;
  margin-right: 0.0833rem;
  padding: 0.125rem;
}

.selected-option:focus {
  outline: 0.125rem solid black;
}

.selected-option.open {
  background-color: white;
}

.selected-option span.arrow {
  margin-left: 1.875rem;
}

.arrow img {
  width: 1.3rem;
  height: 1rem;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 12.5rem;
  overflow-y: auto;
  background-color: #ffffff;
  border: 0.0625rem solid #ccc;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.option {
  padding: 0.5rem 0.75rem;
  cursor: pointer;
}

.option img {
  width: 2.7rem;
  height: 2rem;
  margin-right: 0.2rem;
  padding: 0.125rem;
}

.option:hover {
  background-color: #7e7878;
}

/* /////////////////////////// */

.registration-box-container {
  background-color: #0133a1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  opacity: 1;
}

.registration-box-container img {
  display: block;
  max-width: 6rem;
  max-height: 6rem;
  width: auto;
  height: auto;
  margin: 1.125rem;
}

.registation-success-header {
  margin: 3.125rem 0 0 0;
  font-style: "lato", sans-serif;
  letter-spacing: 0rem;
  font-size: 1.5rem;
  color: #ffffff;
  opacity: 1;
  border-color: #ff0000;
}

.registration-text {
  margin: 0 0 3.375rem 0;
  width: 34rem;
  font-style: "Lato", sans-serif;
  letter-spacing: 0rem;
  font-size: 1.25rem;
  color: #ffffff;
  opacity: 1;
  text-align: center;
}

@media (min-width: 300px) and (max-width: 424px) {
  html,
  body {
    font-size: 6px;
  }

  .edge {
    background-size: 100vw 100%;

    background-image: url("../../assets/mobile-bg.png");
  }

  .model-container {
    flex-direction: column;
    align-items: center;
    margin: 4rem 8rem 0rem 8rem;
  }

  .modal-bg {
    width: 100vw;
    height: auto;
  }

  .model-header {
    margin: 0;
  }

  .model-text {
    margin: 2rem 0rem 0 0;
  }

  .request-demo-btn {
    margin: 1.8rem 1rem 1.8rem 1rem;
  }

  .model-components {
    margin: 4rem 0 0 0;
    align-items: center;
  }

  .features {
    flex-direction: column;
    padding: 5rem 12rem 5rem 12rem;
  }

  .features-container {
    flex-direction: column;
  }

  .feature-desc {
    padding: 0rem 4rem 3rem 4rem;
    align-items: flex-start;
    justify-content: center;
  }

  .our-tech-desc {
    padding: 0rem 4rem 3rem 4rem;
    align-items: flex-start;
    justify-content: center;
  }

  .our-tech {
    padding: 4rem 5rem 0rem 5rem;
  }

  .our-tech-container {
    flex-direction: column;
    margin: 0 0 10rem 0;
  }

  .tech-image {
    max-width: 81vw;
    max-height: 81vw;
  }

  .start {
    background-size: 75vw 45vw;
    background-repeat: no-repeat;
    background-image: url("../../assets/circle-bg.png");
    padding: 2rem 0rem 1rem 0rem;
  }

  .guide-items {
    flex-direction: column;
    margin: 3rem 0 1rem 0;
  }

  .upload-model-text {
    width: 27rem;
  }

  .tech-items {
    flex-direction: column;
  }

  .tech {
    margin: 2rem 0 2rem 0;
  }

  .platform-container {
    flex-direction: column;
  }

  .iframe {
    width: 80vw;
    height: 40rem;
    border: none;
  }

  .multi-iframe {
    width: 90vw;
    height: 100vh;
  }

  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer-logo-container {
    align-items: center;
    justify-content: center;
  }

  .contact-us-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .video-container {
    width: 90vw;
  }

  .video-player {
    width: 78.3vw;
    height: 49.5vw;
  }

  .user-details {
    margin: 1rem 0 1rem 0;
  }

  .user-guide-container {
    padding: 2rem 0 10rem 0;
  }

  .iframe-container {
    /* background-size: 42vw;
    background-position: bottom;
    background-repeat: no-repeat;
    background-image: url("../../assets/model-bg.png"); */
  }

  .platform-bg {
    padding: 8rem 4rem 4rem 4rem;
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
  }
}

@media (min-width: 425px) and (max-width: 550px) {
  html,
  body {
    font-size: 7px;
  }

  .edge {
    background-size: 100vw 100%;
    background-image: url("../../assets/mobile-bg.png");
  }

  .model-container {
    margin: 0rem 5rem 0rem 5rem;
    flex-direction: column;
    align-items: center;
  }

  .modal-bg {
    width: 100vw;
    height: auto;
  }

  .model-header {
    margin: 0;
  }

  .model-text {
    margin: 2rem 0rem 0 0;
  }

  .request-demo-btn {
    margin: 1.8rem 1rem 1.8rem 1rem;
  }

  .model-components {
    margin: 4rem 0 0 0;
    align-items: center;
  }

  .features {
    flex-direction: column;
    padding: 0rem 12rem 7.5625rem 12rem;
  }

  .features-container {
    flex-direction: column;
  }

  .feature-desc {
    padding: 0rem 4rem 3rem 4rem;
    align-items: flex-start;
    justify-content: center;
  }

  .our-tech-desc {
    padding: 0rem 4rem 3rem 4rem;
    align-items: flex-start;
    justify-content: center;
  }

  .our-tech {
    padding: 4rem 5rem 0rem 5rem;
  }

  .our-tech-container {
    flex-direction: column;
    margin: 0 0 10rem 0;
  }

  .tech-image {
    max-width: 81vw;
    max-height: 81vw;
  }

  .start {
    padding: 2rem 0rem 3rem 0rem;
    background-size: 75vw 45vw;
    background-repeat: no-repeat;
    background-image: url("../../assets/circle-bg.png");
  }

  .guide-items {
    flex-direction: column;
    margin: 3rem 0 1rem 0;
  }

  .upload-model-text {
    width: 27rem;
  }

  .tech-items {
    flex-direction: column;
  }

  .tech {
    margin: 2rem 0 2rem 0;
  }

  .platform-container {
    flex-direction: column;
  }

  .iframe {
    width: 80vw;
    height: 40rem;
    border: none;
  }

  .multi-iframe {
    width: 90vw;
    height: 100vh;
  }

  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer-logo-container {
    align-items: center;
    justify-content: center;
  }

  .contact-us-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .video-player {
    width: 78.3vw;
    height: 49.5vw;
  }

  .contact-us-container {
    justify-content: center;
    width: 100%;
  }

  .contact-us {
    margin: 4rem 0rem 2rem 0rem;
  }

  .user-details {
    margin: 1rem 0 1rem 0;
  }

  .user-guide-container {
    padding: 2rem 0 10rem 0;
  }

  .iframe-container {
    background-size: 42vw;
    background-position: bottom;
    background-repeat: no-repeat;
  }

  .platform-bg {
    padding: 8rem 4rem 4rem 4rem;
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
  }
}

@media (min-width: 551px) and (max-width: 675px) {
  html,
  body {
    font-size: 8px;
  }

  .edge {
    background-size: 100vw 100%;
    background-image: url("../../assets/mobile-bg.png");
  }

  .model-container {
    margin: 0rem 5rem 0rem 5rem;
    flex-direction: column;
    align-items: center;
  }

  .modal-bg {
    width: 100vw;
    height: auto;
  }

  .model-header {
    margin: 0;
  }

  .model-text {
    margin: 2rem 0rem 0 0;
  }

  .request-demo-btn {
    margin: 1.8rem 1rem 1.8rem 1rem;
  }

  .model-components {
    margin: 4rem 0 0 0;
    align-items: center;
  }

  .features {
    flex-direction: column;
    padding: 0rem 12rem 7.5625rem 12rem;
  }

  .features-container {
    flex-direction: column;
  }

  .feature-desc {
    padding: 0rem 4rem 3rem 4rem;
    align-items: flex-start;
    justify-content: center;
  }

  .our-tech-desc {
    padding: 0rem 4rem 3rem 4rem;
    align-items: flex-start;
    justify-content: center;
  }

  .our-tech {
    padding: 4rem 5rem 0rem 5rem;
  }

  .our-tech-container {
    flex-direction: column;
    margin: 0 0 10rem 0;
  }

  .tech-image {
    max-width: 81vw;
    max-height: 81vw;
  }

  .start {
    padding: 2rem 0rem 3.5rem 0rem;
    background-size: 75vw 45vw;
    background-repeat: no-repeat;
    background-image: url("../../assets/circle-bg.png");
  }

  .guide-items {
    flex-direction: column;
    margin: 3rem 0 1rem 0;
  }

  .upload-model-text {
    width: 27rem;
  }

  .tech-items {
    flex-direction: column;
  }

  .tech {
    margin: 2rem 0 2rem 0;
  }

  .platform-container {
    flex-direction: column;
  }

  .iframe {
    width: 80vw;
    height: 40rem;
    border: none;
  }

  .multi-iframe {
    width: 90vw;
    height: 100vh;
  }

  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer-logo-container {
    align-items: center;
    justify-content: center;
  }

  .contact-us-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .video-player {
    width: 78.3vw;
    height: 49.5vw;
  }

  .contact-us-container {
    justify-content: center;
    width: 100%;
  }

  .contact-us {
    margin: 4rem 0rem 2rem 0rem;
  }

  .user-details {
    margin: 1rem 0 1rem 0;
  }

  .user-guide-container {
    padding: 2rem 0 10rem 0;
  }

  .iframe-container {
    background-size: 42vw;
    background-position: bottom;
    background-repeat: no-repeat;
  }

  .platform-bg {
    padding: 8rem 4rem 4rem 4rem;
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
  }
}

@media (min-width: 676px) and (max-width: 799px) {
  html,
  body {
    font-size: 9px;
  }

  .edge {
    background-size: 100vw 100%;
    background-image: url("../../assets/mobile-bg.png");
  }

  .model-container {
    margin: 0rem 5rem 0rem 5rem;
    flex-direction: column;
    align-items: center;
  }

  .modal-bg {
    width: 100vw;
    height: auto;
  }

  .model-header {
    margin: 0;
  }

  .model-text {
    margin: 2rem 0rem 0 0;
  }

  .request-demo-btn {
    margin: 1.8rem 1rem 1.8rem 1rem;
  }

  .model-components {
    margin: 4rem 0 0 0;
    align-items: center;
  }

  .features {
    flex-direction: column;
    padding: 0rem 12rem 7.5625rem 12rem;
  }

  .features-container {
    flex-direction: column;
  }

  .feature-desc {
    padding: 0rem 4rem 3rem 4rem;
    align-items: flex-start;
    justify-content: center;
  }

  .our-tech-desc {
    padding: 0rem 4rem 3rem 4rem;
    align-items: flex-start;
    justify-content: center;
  }

  .our-tech {
    padding: 4rem 5rem 0rem 5rem;
  }

  .our-tech-container {
    flex-direction: column;
    margin: 0 0 10rem 0;
  }

  .tech-image {
    max-width: 81vw;
    max-height: 81vw;
  }

  .start {
    padding: 2rem 0rem 4rem 0rem;
    background-size: 75vw 45vw;
    background-repeat: no-repeat;
    background-image: url("../../assets/circle-bg.png");
  }

  .guide-items {
    flex-direction: column;
    margin: 3rem 0 1rem 0;
  }

  .upload-model-text {
    width: 27rem;
  }

  .tech-items {
    flex-direction: column;
  }

  .tech {
    margin: 2rem 0 2rem 0;
  }

  .platform-container {
    flex-direction: column;
  }

  .iframe {
    width: 80vw;
    height: 40rem;
    border: none;
  }

  .multi-iframe {
    width: 90vw;
    height: 100vh;
  }

  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer-logo-container {
    align-items: center;
    justify-content: center;
  }

  .contact-us-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .video-player {
    width: 78.3vw;
    height: 49.5vw;
  }

  .contact-us-container {
    justify-content: center;
    width: 100%;
  }

  .contact-us {
    margin: 4rem 0rem 2rem 0rem;
  }

  .user-details {
    margin: 0.7rem 0 0.7rem 0;
  }

  .user-guide-container {
    padding: 2rem 0 10rem 0;
  }

  .iframe-container {
    background-size: 42vw;
    background-position: bottom;
    background-repeat: no-repeat;
  }

  .platform-bg {
    padding: 8rem 4rem 4rem 4rem;
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
  }
}

@media (min-width: 800px) and (max-width: 1024px) {
  html,
  body {
    font-size: 10px;
  }

  .edge {
    background-size: 100vw 100%;
    background-image: url("../../assets/mobile-bg.png");
  }

  .model-container {
    margin: 0rem 5rem 0rem 5rem;
    flex-direction: column;
    align-items: center;
  }

  .modal-bg {
    width: 100vw;
    height: auto;
  }

  .model-header {
    margin: 0;
  }

  .model-text {
    margin: 2rem 0rem 0 0;
  }

  .request-demo-btn {
    margin: 1.8rem 1rem 1.8rem 1rem;
  }

  .model-components {
    margin: 4rem 0 0 0;
    align-items: center;
  }

  .features {
    flex-direction: column;
    padding: 0rem 12rem 7.5625rem 12rem;
  }

  .features-container {
    flex-direction: column;
  }

  .feature-desc {
    padding: 0rem 4rem 3rem 4rem;
    align-items: flex-start;
    justify-content: center;
  }

  .our-tech-desc {
    padding: 0rem 2rem 3rem 2rem;
    align-items: flex-start;
    justify-content: center;
  }

  .our-tech {
    padding: 4rem 5rem 0rem 5rem;
  }

  .our-tech-container {
    flex-direction: column;
    margin: 0 0 10rem 0;
  }

  .tech-image {
    max-width: 81vw;
    max-height: 81vw;
  }

  .start {
    padding: 2rem 0rem 6rem 0rem;
    background-size: 75vw 45vw;
    background-repeat: no-repeat;
    background-image: url("../../assets/circle-bg.png");
  }

  .guide-items {
    flex-direction: column;
    margin: 3rem 0 1rem 0;
  }

  .upload-model-text {
    width: 27rem;
  }

  .tech-items {
    flex-direction: column;
  }

  .tech {
    margin: 2rem 0 2rem 0;
  }

  .platform-container {
    flex-direction: column;
  }

  .iframe {
    width: 80vw;
    height: 40rem;
    border: none;
  }

  .multi-iframe {
    width: 90vw;
    height: 100vh;
  }

  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer-logo-container {
    align-items: center;
    justify-content: center;
  }

  .contact-us-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .video-player {
    width: 78.3vw;
    height: 49.5vw;
  }

  .contact-us-container {
    justify-content: center;
    width: 100%;
  }

  .contact-us {
    margin: 4rem 0rem 2rem 0rem;
  }

  .user-details {
    margin: 0.7rem 0 0.7rem 0;
  }

  .user-guide-container {
    padding: 2rem 0 10rem 0;
  }

  .iframe-container {
    background-size: 42vw;
    background-position: bottom;
    background-repeat: no-repeat;
  }

  .platform-bg {
    padding: 8rem 4rem 4rem 4rem;
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  html,
  body {
    font-size: 9.92px;
  }
}

@media (min-width: 1281px) and (max-width: 1536px) {
  html,
  body {
    font-size: 12px;
  }
}

@media (min-width: 1537px) and (max-width: 1792px) {
  html,
  body {
    font-size: 14px;
  }
}

@media (min-width: 1793px) and (max-width: 2048px) {
  html,
  body {
    font-size: 16px;
  }
}

@media (min-width: 2049px) and (max-width: 2560px) {
  html,
  body {
    font-size: 18px;
  }
}

@media (min-width: 2561px) and (max-width: 3840px) {
  html,
  body {
    font-size: 20px;
  }
}
