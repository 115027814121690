.sidebar_hamburger_menu {
  position: fixed;
  top: 1rem;
  left: 1rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #d4d0d0;
  cursor: pointer;
  z-index: 10;
  transition: all 0.2s ease-in-out;
}
.sidebar_hamburger_menu img {
  width: 1.5rem;
  height: 1.5rem;
}
.sidebar_container {
  position: absolute;
  top: 0;
  transition: all 0.2s ease-in-out;

  z-index: 1;
  height: 100dvh;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 1.7rem;
}
.sidebar_nav_container {
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar_list {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  list-style: none;
  padding: 0;
}

.sidebar_list li a {
  text-decoration: none;
  color: black;
  font-size: 1rem;
}

.sidebar_list a {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}
.sidebar_list a.active {
  color: #0096ff;
}
.sidebar_list a img {
  width: 1.5rem;
  height: 1.5rem;
}

.navbar_screen {
  position: absolute;
  top: 0;
  left: 0;
  height: 100dvh;
  width: auto;
  background-color: white;
  z-index: 10;
  padding: 2rem;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.navbar_close_icon {
  position: absolute;
  top: 2rem;
  left: 2rem;
}
.navbar_close_icon img {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}
