.cohortMenu_container {
  padding: 1.5rem 3.5rem;
  background-color: white;
  border-radius: 1.5rem;
  position: relative;
}
.cohortMenu_close_icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.cohortMenu_close_icon img {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}
.cohortMenu_contents {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  flex-direction: column;
  justify-content: center;
}
.cohort_title {
  font-size: 2rem;
  font-weight: 600;
}
.cohort_subheading {
  font-size: 1.5rem;
  font-weight: 500;
}
.cohort_dropdown_container {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.cohort_dropdown_heading {
  font-size: 1.5rem;
}
.cohort_dropdown {
  position: relative;
  border: 1px solid #000000;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  width: 30rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cohort_dropdown img {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.cohort_dropdown_menu_heading {
  font-size: 1.3rem;
}

.cohort_downMenu {
  position: absolute;
  top: 3.1rem;
  left: 0;
  width: 100%;

  height: auto;
  border-radius: 0.5rem;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
}
.cohort_downMenu_item {
  background-color: white;
  padding: 0.8rem 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;
  font-size: 1.2rem;
}
.cohort_downMenu_item:hover {
  background-color: #d1d1d1;
}
.cohort_button_container {
  margin: 8rem 0 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
